<template>
    <div class="checkout--groups">
        <div class="group--list">
            <div class="group" v-for="group in content.groups" :key="group.id" :class="{selected: group.isActive}">

                <div class="icon--checked"></div>

                <div class="icon--order"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18.7 198c-8 0-14.6-6.6-14.6-14.6V47.9c0-1.2.9-2.1 2.1-2.1h54.2V16.5c0-.2 0-.4.1-.6 0 0 0-.1.1-.2v-.1l6.2-12.4c.4-.8 1.1-1.2 1.9-1.2h62.5c.8 0 1.5.4 1.9 1.2l6.2 12.4s0 .1.1.2c0 .1 0 .1.1.2.1.2.1.4.1.6v29.3h54.2c1.1 0 2.1.9 2.1 2.1v135.5c0 8-6.6 14.6-14.6 14.6H18.7zM8.3 183.4c0 5.7 4.7 10.4 10.4 10.4h162.6c5.7 0 10.4-4.7 10.4-10.4V50H8.3v133.4zM127.1 45.8h8.3V18.7h-8.3v27.1zm-50 0H123V18.7H77.1v27.1zm-12.5 0h8.3V18.7h-8.3v27.1zm1.3-31.3h68.3L130 6.2H70l-4.1 8.3zm61.7 166.6c-1.1 0-2.1-.9-2.1-2.1 0-1.2.9-2.1 2.1-2.1h50c1.2 0 2.1.9 2.1 2.1 0 1.1-.9 2.1-2.1 2.1h-50zm50.6 5.4"/></svg></div>

                <div class="content">
                    <div class="title">{{group.name}}</div>
                    <div class="selection_info" v-if="group.collection.selected === group.collection.total">
                        <div class="product_count">{{group.pairs}} <span>Total Quantity</span></div>
                        <div class="price_2">{{group.uvp}} € <span>RRP</span></div>
                        <!--
                        <div class="price_1">{{group.ek}} € <span>WHS</span></div>
                        -->
                    </div>
                    <div class="not_all_selected" v-else-if="group.collection.selected > 0">{{group.collection.selected}} von {{group.collection.total}} Artikel gewählt</div>
                    <div class="none_selected" v-else>Kein Sortiment gewählt</div>
                </div>

                <div class="article_count">{{group.articles}} Products</div>

            </div>
        </div>
    </div>
</template>

<script>
    import InlineSvg from 'vue-inline-svg';
    import {handleContentScroll} from "@/assets/js/mixins/handleContentScroll";

    export default {
        name: 'CheckoutGroups',
        components: {InlineSvg},
        mixins: [handleContentScroll],
        props: { state:{type: Object}, content:{type: Object} },
        computed: {
        },
        methods: {
            scrollPercent(p){
                let container = document.querySelector('.group--list');
                this.handleScrollPercent(container,p);
            },
        }
    }
</script>

<style scoped lang="scss">

    .checkout--groups {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        font-family: "Inter Light";

        .group--list{
            height: 100%;
            padding: 6vh;
            box-sizing: border-box;

            display: flex;
            flex-direction: column;
            overflow: hidden;

            scroll-behavior: smooth;
            //justify-content: space-evenly;

            .group {
                display: flex;
                //flex: 1;
                height: 25%;
                min-height: 25%;
                padding: 0 5vh;
                align-items: center;

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                }

                .icon--checked {
                    display: block;
                    border-radius: 50%;
                    border: solid 2px white;
                    width: 2.5vh;
                    height: 2.5vh;
                    opacity: 0.2;
                    background-position: center;
                    background-size: 110%;
                }
                .icon--order{
                    width: 4vw;
                    padding: 0 5vw;
                    svg path{
                        fill: rgba(255,255,255,0.6);
                    }
                }
                .content{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    .title{
                        font-size: 5vh;
                        letter-spacing: 0.1vh;
                        margin-bottom: 2vh;
                    }
                    .not_all_selected,
                    .none_selected{
                        opacity: 0.5;
                        font-size: 2vh;
                        font-weight: 100;
                        letter-spacing: 0.1vh;
                    }
                    .selection_info{
                        display: flex;
                        width: 70%;
                        .product_count,
                        .price_1,
                        .price_2{
                            font-size: 2vh;
                            flex: 1;
                            margin-right: 5vw;
                            letter-spacing: 0.1vh;
                            span{
                                font-weight: 100;
                                opacity: 0.5;
                            }
                        }
                    }
                }

                .article_count{
                    font-weight: 100;
                    opacity: 0.4;
                    font-size: 2.5vh;
                    text-transform: uppercase;
                    letter-spacing: 0.4vh;
                }



                .icon--checked{
                    transition: all 0.5s;
                }
                &.selected{
                    .icon--checked{
                        opacity: 1;
                        background-image: url('../../assets/img/ui-checked-white.svg');
                    }
                }
            }
        }
    }

</style>