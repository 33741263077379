import {priceFormatter} from "@/utils/priceFormatter";
export const priceFormatterMixin = {
    props: {
        settings: {type: Object}
    },
    methods: {
        priceFormatter(price) {
            return priceFormatter(price, this.settings.language, this.settings.currency)
        },
    }
}