<template>
    <video class="fullscreen"
           :class="{'object-fit': objectFit}"
           @canplay="canplay"
           :poster="content.thumb ? buildAssetPath(content.thumb) : ''"
           :key="content.video"
           ref="video"
           v-bind="attributes">
        <source :src="buildAssetPath(content.video)">
    </video>
</template>

<script>
    import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
    export default {
        name: 'FullscreenVideo',
        props: { state:{type: Object}, content:{type: Object}, settings: {type: Object}  },
        mixins: [AssetPathBuilder],
        data: () => ({
            dateKey: undefined,
            videoSrc: undefined
        }),
        computed: {
            objectFit() {
                return this.state && this.state.fit
            },
            player() {
                return this.$refs.video;
            },
            attributes(){
                let attrs = {};
                
                if(this.state && this.state.loop) {
                    attrs.loop = true;
                }
                
                //if(this.state && (this.state.volume || this.state.volume === 0)) attrs['volume'] = this.state.volume;
                
                if(this.state && this.state.muted) {
                    attrs.muted = true;
                }

                if(this.state && !this.state.autoplay) {
                    attrs.muted = false
                    attrs.autoplay = false
                } else {
                    attrs.autoplay = true
                }

                // if(this.state && !this.state.autoplay) attrs['muted'] = true;
                return attrs;
            },
        },
        methods: {
            setMute(args) {
                this.player.muted =  args.muted;
            },
            canplay(){
                //if(this.state && this.state.loop) this.player.loop = true;
                //this.player.volume = this.state && this.state.volume && this.state.volume !== 0 ? this.state.volume : 1;
            },
            setVolume(v){
                this.player.volume = v;
            },
            playVideo(args) {
                if(args && args.currentTime) {
                    this.player.currentTime = args.currentTime;
                }
                if(args && args.muted) {
                    this.player.muted = args.muted
                }
                this.player.play()
            },
            pauseVideo() {
                if(this.player)
                {
                    this.player.pause()
                }
            },
            setTime(args) {
                if(args && args.currentTime) {
                    this.player.currentTime = args.currentTime;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
    &.object-fit {
        object-fit: cover;
    }
}
</style>