import Vue from 'vue';
import VueRouter from "vue-router";

import App from './App.vue';

// dynamically register all view components globally
import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';
const requireComponent = require.context('./components', true, /\w+\.(vue|js)$/ );
requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName);
    const componentName = upperFirst(camelCase(fileName.split('/').pop().replace(/\.\w+$/, '')));
    Vue.component(componentName,componentConfig.default || componentConfig);
});

// https://github.com/Akryum/vue-virtual-scroller
import { RecycleScroller } from 'vue-virtual-scroller';
Vue.component('RecycleScroller', RecycleScroller);

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

// Router (only needed to get url params, no actual routing)
Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        { path: '/', component: App },
    ]
});

Vue.prototype.$defaultCurrency = '€';

new Vue({
    el: '#app',
    router,
    render: h => h(App),
});