<template>
    <div class="product--list">
        <div class="list--item" v-for="(item,i) in content.items" :key="item.id" :data-item-id="item.id" :style="itemSize()">
            <ImageLoader class="product--image" :src="buildAssetPath(item.image.replace('.png', '-thumb.png'))"></ImageLoader>
        </div>
    </div>
</template>

<script>
    import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";

    export default {
        name: 'ProductList',
        props: { content:{type: Object}, state:{type: Object}, settings: {type: Object}  },
        mixins: [AssetPathBuilder],
        data(){
            return {
                ratio: null,
                rows: null,
                cols: null,
                row_height: null,
                col_width: null,
            }
        },
        computed: {
            itemCount(){ return this.content.items.length },
        },
        methods: {
            scrollToItemId(args){
                document.querySelector(`.list--item[data-item-id="${args.itemId}"`).scrollIntoView();
            },
            itemSize(){
                return {width: this.col_width+'px', height: this.row_height+'px'}
            }
        },
        mounted(){
            let list = document.querySelector(".product--list");
            let bounds = list.getBoundingClientRect();

            this.ratio = bounds.width / bounds.height;
            this.rows = Math.ceil(Math.sqrt(this.itemCount / this.ratio ));
            this.cols = Math.floor(this.rows * this.ratio);
            this.row_height = bounds.height / this.rows;
            this.col_width = bounds.width / this.cols;
        }
    }
</script>

<style scoped lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .product--list {
        background: white;
        scroll-behavior: smooth;
        display: flex;
        align-content: flex-start;
        //justify-content: center;
        flex-wrap: wrap;
        //padding: 1vw;
        box-sizing: border-box;

        .list--item{
            .product--image{
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                transition: all 0.5s;
            }
        }
    }
</style>