<template>
    <div class="header--element" :class="{'white--text': white}">
        <div class="header--element-title">
            <slot />
        </div>
        <div v-if="hasLabelSlot" class="header--element-sublabel">
            <slot name="label" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Headline',
    props: {
        white: {
            type: Boolean
        },
    },
    computed: {
        hasLabelSlot () {
            return !!this.$slots['label']
        }
    }
}
</script>

<style lang="scss" scoped>
    .header--element {
        color: rgba(0, 0, 0, 0.87);
        padding-left: 0.9vw;
        margin-bottom: 2vh;
        display: flex;
        flex-direction: column;
        &.white--text {
          color: #fff;
        }
        &-title {
            display: inline-block;
            font-family: "Inter Bold";
            font-size: 2.8vh;
            line-height: 1.5;
        }
        &-sublabel {
            font-family: "Inter Extra Light";
            font-size: 1.8vh;
            line-height: 1.2;
            opacity: .7;
        }

        &.dense{
            display: flex;
            flex-direction: row;
            align-items: center;

            .header--element-title {
                text-decoration: underline;
                font-size: 2.2vh;
            }
            .header--element-sublabel {
                margin-left: 1vw;
                background-color: #f0f0f0;
                border-radius: 2vh;
                width: 5vh;
                height: 3vh;
                display: flex;
                justify-content: center;
                align-items: center;

                font-family: "Inter Bold";
                font-size: 1.8vh;
                line-height: 1.8vh;
            }
        }
    }
</style>
