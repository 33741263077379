<template>
    <div class="product--catalogue">
        <div class="content">
            <RecycleScroller
                class="product--grid"
                :items="itemRows"
                :item-size="getItemHeight()"
                key-field="id"
                v-slot="{ item, index, active }"
                ref="RecycleScroller"
            >
                <transition name="fade" mode="out-in">
                    <div class="itemRowWrapper" v-if="active">
                        <div class="grid--item" v-for="(_item,i) in item.items" :class="itemClass(_item)" :key="_item.id" :data-item-id="_item.id">
                            <image-loader class="product--image" :src="buildAssetPath(getImageThumb(_item))"></image-loader>
                            <transition name="fade" appear>
                                <div class="item--description">
                                    <div class="title">{{_item.name}}</div>
                                    <div class="productId">{{_item.sku}}</div>
                                    <div class="price">{{ priceFormatter(_item.price) }} <!--<span>{{ priceFormatter(_item.ek) }}</span>--></div>
                                </div>
                            </transition>
                            <transition name="fade" mode="out-in">
                                <div v-show="isItemInUse(_item)" class="item--checked">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                                        <path d="M0 106.4L68.5 175 200 43.6 181.1 25 68.5 137.5 18.6 87.6 0 106.4z"/>
                                    </svg>
                                </div>
                            </transition>
                        </div>
                    </div>
                </transition>
            </RecycleScroller>


            <!--<transition-group tag="div" class="product--grid" name="scale-transition" mode="out-in">
                <div class="grid--item" v-for="(item,i) in content.items" :key="item.id" :class="itemClass(item)" :data-item-id="item.id">
                    <image-loader class="product--image" :src="buildAssetPath(getImageThumb(item))"></image-loader>
                    <transition name="fade" appear>
                        <div class="item--description">
                            <div class="title">{{item.name}}</div>
                            <div class="productId">{{item.category}}</div>
                            <div class="price">{{ priceFormatter(item.price)}}</div>
                        </div>
                    </transition>
                    <transition name="fade" mode="out-in">
                        <div v-show="isItemInUse(item)" class="item--checked">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                                <path d="M0 106.4L68.5 175 200 43.6 181.1 25 68.5 137.5 18.6 87.6 0 106.4z"/>
                            </svg>
                        </div>
                    </transition>
                </div>
            </transition-group>-->
        </div>
    </div>
</template>

<script>
import { priceFormatterMixin } from "@/mixins/priceFormatterMixin";
import {handleContentScroll} from "@/assets/js/mixins/handleContentScroll";
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
    export default {
        name: 'ProductCatalogue',
        mixins: [priceFormatterMixin, handleContentScroll, AssetPathBuilder],
        props: { content:{type: Object}, state:{type: Object}, settings: {type: Object} },
        data() {
            return {
                rowSize: 6,     // items per row
            }
        },
        computed: {
            inUseClass(){
                return this.state && this.state.inUseClass ? this.state.inUseClass : 'grayscale';
            },
            ViewportWidth(){
                return window.innerWidth;
            },
            ViewportHeight(){
                return window.innerHeight;
            },
            itemRows(){
                let arr = this.content.items;

                let rows = [];
                let count = 0;
                while(arr.length) {
                    rows.push({'id': count, 'items': arr.splice(0,this.rowSize)});
                    count++;
                }

                return rows;
            }
        },
        methods: {
            getItemWidth(){
                return this.ViewportWidth / this.rowSize;
            },
            getItemHeight(){
                return this.ViewportHeight / 3;
            },
            getImageThumb(item) {
                return item.image.replace('/shoes/', '/shoes/thumbs/')
            },
            isItemInUse(item){
                let used = this.content.usedId || [];
                return used.includes(item.id);
            },
            itemClass(item){
                let classes = {
                    in_use: this.isItemInUse(item),
                };
                classes[this.inUseClass] = true;
                return classes;
            },
            /*scrollToItemId(args){
                document.querySelector(`.grid--item[data-item-id="${args.itemId}"`).scrollIntoView();
            },*/
            scrollPercent(p){
                //let container = document.querySelector('.product--catalogue');
                //let container = document.querySelector('.vue-recycle-scroller__item-wrapper');
                this.handleScrollPercent(this.$refs.RecycleScroller.$el,p);
            },
        }
    }
</script>
<style scoped lang="scss">

    .item--checked {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 3vw;
        height: 3vw;
        margin-left: -1.5vw;
        margin-top: -1.5vw;
        background-color: rgba(0,0,0,.5);
        backdrop-filter: blur(5px);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        svg {
            width: 30%;
            fill: #fff;
        }
    }

    .scroll-y-transition-move {
        transition: transform 5s;
    }

    .scroll-y-transition-enter, .scroll-y-transition-leave-to {
        opacity: 0;
    }

    .scroll-y-transition-enter {
        transform: translateY(15px);
    }

    .scroll-y-transition-leave-to {
        transform: translateY(-15px);
    }

    .transform-move {
        transition: transform .6s;
    }

    .scale-transition-enter-active, .scale-transition-leave-active {
        -webkit-transition: .3s cubic-bezier(0.25, 0.8, 0.5, 1);
        transition: .3s cubic-bezier(0.25, 0.8, 0.5, 1)
    }

    .scale-transition-move {
        -webkit-transition: -webkit-transform .6s;
        transition: -webkit-transform .6s;
        transition: transform .6s;
        transition: transform .6s, -webkit-transform .6s
    }

    .scale-transition-enter, .scale-transition-leave, .scale-transition-leave-to {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0)
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .product--catalogue {
        background: white;
        scroll-behavior: smooth;
        .content{
            display: flex;
            .product--grid{
                scroll-behavior: smooth;
                flex-grow: 1;
                display: flex;
                flex-wrap: wrap;
                padding: 1vw;

                max-height: 100vh;
                height: 100vh;

                &.vue-recycle-scroller.direction-vertical{
                    margin-right: -17px; // hide scrollbar
                    overflow-y: scroll; // always "show" scrollbar, even if not needed, to maintain margins
                }

                .itemRowWrapper{
                    display: flex;
                }

                .grid--item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    max-width: calc(100%/6);
                    max-height: 33vh;
                    width: calc(100%/6);
                    height: 33vh;
                    position: relative;
                    .product--image{
                        max-width: 100%;
                        //max-height: 70%;
                        height: 70%;
                        object-fit: contain;
                        transition: all 0.5s;
                    }
                    &.in_use{
                        &.grayscale{
                            .product--image{
                                filter: grayscale(1);
                                opacity: 0.2;
                            }
                        }
                    }

                    .item--description{
                        font-family: "Inter Light";
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        max-height: 30%;
                        margin-top: -5%;
                        max-width: 100%;
                        .title{
                            font-size: 2vh;
                            font-family: "Inter Bold";
                            span{
                                font-family: "Inter Extra Light";
                                padding-left: 0.2vw;
                            }

                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            max-width: 100%;
                        }
                        .productId{
                            font-family: "Inter Extra Light";
                            margin-top: 0.5vh;
                            //letter-spacing: 0.3vh;
                            font-size: 1.2vh;
                        }
                        .price{
                            font-family: "Inter Bold";
                            font-size: 1.2vh;
                            padding: 0.6vh 0.9vw;
                            border-radius: 20vh;
                            //margin-top: 1.5vh;
                            //background-color: rgba(245, 245, 245, 1);

                            span {
                                color: #737373;
                                font-size: 0.75em;
                                padding-left: 0.25em;
                            }
                        }
                    }
                }
            }
        }

    }
</style>