<template>
    <div class="product--detail">
        <ImageLoader class="background" :src="buildAssetPath('/assets/img/background-product.jpg')"></ImageLoader>

        <div class="content">
            <div class="product--image">
                <transition name="fade-down" appear>
                    <item-detail-heading :item="item" :pricewhs="priceFormatter(item.ek)" :price="priceFormatter(item.price)"></item-detail-heading>
                </transition>

                <transition name="scroll-x-transition" mode="out-in" appear>
                    <div class="text-center" :key="item.id">
                        <ImageLoader v-if="!isAnm360" class="product--image__image"
                                     :src="buildAssetPath(item.image)" ></ImageLoader>
                    </div>
                </transition>

                <transition :name="isAnm360 ? 'fade360' : ''">
                    <div v-if="isAnm360" class="product-detail__video-wrapper">
                        <video class="product-detail__video">
                            <source :src="buildAssetPath('/assets/360/' + item.sku + '.webm')" type='video/webm'/>
                        </video>
                    </div>
                </transition>

            </div>
            <div class="product--sidebar" :class="[{ open: sidebarOpen }, sidebarClass ]">
                <product-sidebar-functions :item="item" :settings="settings" v-if="sidebarClass === 'icons'"></product-sidebar-functions>
            </div>
        </div>

    </div>
</template>

<script>
import {priceFormatterMixin} from "@/mixins/priceFormatterMixin";
import ProductSidebarFunctions from "../ProductSidebarFunctions";
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
import {handleContentScroll} from "@/assets/js/mixins/handleContentScroll";

export default {
    name: 'ProductDetail',
    mixins: [priceFormatterMixin, AssetPathBuilder, handleContentScroll],
    components: {
        ProductSidebarFunctions,
        ItemDetailHeading: () => import('@/components/ItemDetailHeading'),
    },
    props: {
        content: {type: Object},
        state: {type: Object},
        settings: {type: Object}
    },
    data() {
        return {
            isAnm360: false,
            anm360: null,
            fsOnly: false,
            showImages: true
        }
    },
    computed: {
        item() {
            return this.content.item;
        },
        sidebarOpen() {
            return this.state.sidebar ? 'open' : '';
        },
        sidebarClass() {
            return this.state.sidebarClass || 'sizes';
        },
        profileView() {
            return this.state.profileView || false;
        },
    },
    methods: {
        init360(args) {
            this.isAnm360 = true;
            this.anm360 = args.data;
        },
        run360() {
            var self = this;

            if (this.isAnm360) {
                this.anm360.video = document.querySelector('.product-detail__video');
                this.anm360.seeking = false;

                this.anm360.video.addEventListener("loadeddata", function () {

                    self.anm360.duration = this.duration;
                    self.anm360.frame = this.duration / 36;

                    this.playbackRate = 16;

                    setTimeout(() => {
                        self.anm360.video.play();
                    }, 2300);

                    this.addEventListener('seeked', function () {
                        self.anm360.seeking = false;
                    });
                });
            }
        },
        rotate360(args) {

            if (this.anm360 && !this.anm360.seeking) {
                this.anm360.time = args.data.time;
                this.anm360.video.currentTime = this.anm360.time

                this.anm360.seeking = true;
            }
        },
        stop360() {
            this.isAnm360 = false;
        },
        setFSonly(data) {
            this.fsOnly = data.fsOnly;
        },
        zoomImg(args) {

            let img = document.querySelector('.product--image__image');

            document.documentElement.style.setProperty('--moveX', args.pos_x + '%');
            document.documentElement.style.setProperty('--moveY', args.pos_y + '%');

            document.documentElement.style.setProperty('--zoom', args.level);
        },
        scrollPercent(p) {
            let container = document.querySelector('.product--collection');
            if(container)
            {
                this.handleScrollPercent(container,p);
            }
        },
    },
    watch: {
        content: {
            deep: true,
            handler() {
                if (this.content.fsOnly) {
                    this.fsOnly = this.content.fsOnly;
                }
            }
        },
        'item.images': {
            deep: true,
            handler() {
                this.showImages = false
                setTimeout(() => {
                    this.showImages = true
                }, 600)
            }
        }
    },
    updated() {
        this.run360();
    },
    created() {
        document.documentElement.style.setProperty('--moveX', '0%');
        document.documentElement.style.setProperty('--moveY', '0%');

        document.documentElement.style.setProperty('--zoom', 1);
    }

}
</script>

<style scoped lang="scss">
    .scroll-x-transition-enter-active, .scroll-x-transition-leave-active {
        transition: 0.6s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

    .scroll-x-transition-move {
        transition: transform 1.2s;
    }

    .scroll-x-transition-enter, .scroll-x-transition-leave-to {
        opacity: 0;
    }

    .scroll-x-transition-enter {
        transform: translateX(-5vw);
    }

    .scroll-x-transition-leave-to {
        transform: translateX(5vw);
    }


    .scroll-y-transition-enter-active, .scroll-y-transition-leave-active {
        transition: .6s cubic-bezier(.25, .8, .5, 1)
    }

    .scroll-y-transition-move {
        transition: transform 1.2s;
    }

    .scroll-y-transition-enter, .scroll-y-transition-leave-to {
        opacity: 0
    }

    .scroll-y-transition-enter {
        transform: translateY(-30px)
    }

    .scroll-y-transition-leave-to {
        transform: translateY(30px)
    }


    .product--image__image {
        transform: scale(var(--zoom)) translate(var(--moveX), var(--moveY));
    }

    .fade-down-enter-active, .fade-down-leave-active {
        transition: all 1.5s;
    }

    .fade-down-enter, .fade-leave-to {
        opacity: 0;
    }

    .text-center {
        text-align: center;
    }
    .product--detail {
        background: #c3c3c3;

        .background {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .content {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            overflow: hidden;

            .product--image--profile {
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                .profile--top {
                    height: 55%;
                    background: rgba(255, 255, 255, 0.3);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .profile--top__image {
                        max-width: 80%;
                    }
                }

                .profile--bottom {
                    height: 45%;
                    display: flex;
                    flex-direction: row;

                    .profile--back {
                        height: 100%;
                        width: 50%;
                        background: rgba(255, 255, 255, 0.1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .profile--top--bottom {
                        height: 100%;
                        width: 50%;
                        background: rgba(255, 255, 255, 0.2);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;

                        .profile--top__image {
                            position: absolute;
                            left: 0;
                            object-fit: contain;
                        }

                        .profile--bottom__image {
                            position: absolute;
                            right: 0;
                        }
                    }
                }

                .profile--image {
                    max-width: 70%;
                }
            }

            .product--image {
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                .product--image__image {
                    //max-width: 70%;
                    //max-height: 100%;
                    max-width: 70vw;
                    max-height: 100vh;
                    object-fit: contain;
                    overflow: hidden;
                    margin-top: 10%;
                    transition: all .3s ease-in-out;
                }
            }

            .product--sidebar {
                display: flex;
                width: 0;
                min-width: 0;
                background: white;
                transition: all 0.5s;
                overflow: hidden;
                z-index: 10;

                &.open {
                    &.icons {
                        width: 25%;
                        min-width: 25%;
                    }

                    &.sizes {
                        width: 50%;
                        min-width: 50%;
                    }
                }
            }
        }
    }

    .product-detail__video {
        height: 72vh;
        width: 100%;
        margin: 20% auto;
        overflow: hidden;
        opacity: 1;
        transform: rotate(0);
        transition: opacity 2.5s, transform 2.5s;
    }

    .fade360-enter-active > .product-detail__video {
        opacity: 0.9;
        transform: rotate(-15deg);
    }

    .product-detail__video-wrapper {
        max-width: 70%;
        max-height: 100%;
        //overflow: hidden;
        margin-top: 10%;
    }
</style>