import {TimelineMax, TweenLite, Power3} from 'gsap';

export default {
    data() {
        return {
            timelines:{
                detailContainerTimeline: null,
                gridToSlider: null,
            },
            DOM: {
                topic_list: null,
                details_image: null,
                details_content: null,
                items: {},
            },
            currentItem: null,
            tween: {
                sliderGapBetween: null,
                firstItem: {
                    width: null,
                    height: null
                },
                target: {
                    width: null,
                    height: null,
                    locationX: null,
                    locationY: null,
                    centerX: null,
                    centerY: null,
                    scale: 1.4,
                },
                items: [],
            },
            draggable: {
                width: null,
            }
        }
    },
    methods: {
        init_tween(container){
            this.DOM.topic_list = container.querySelector('.topic--list');
            this.DOM.details_image = container.querySelector('.details--image');
            this.DOM.details_content = container.querySelector('.details--content');
            this.DOM.firstItem = this.DOM.topic_list.querySelector('.topic--item:first-of-type');

            this.DOM.slideAnimationTarget = container.querySelector('.slideAnimationTarget');

            this.DOM.details_image.querySelectorAll('.topic--detail--image').forEach((el)=>{
                this.DOM.items[el.dataset.itemId] = {};
                this.DOM.items[el.dataset.itemId].image = el;
                this.tween.items.push({
                    id: el.dataset.itemId,
                });
            });
            this.DOM.details_content.querySelectorAll('.topic--detail--content').forEach((el)=>{
                this.DOM.items[el.dataset.itemId].content = el;
            });
            this.DOM.topic_list.querySelectorAll('.topic--item').forEach((el)=>{
                this.DOM.items[el.dataset.itemId].list_item = el;
            });

            // FirstItem
            let firstItemBound = this.DOM.firstItem.getBoundingClientRect();
            this.tween.firstItem.width = firstItemBound.width;
            this.tween.firstItem.height = firstItemBound.height;

            let targetBound = this.DOM.slideAnimationTarget.getBoundingClientRect();
            this.tween.target.width = targetBound.width;
            this.tween.target.height = targetBound.height;

            this.tween.target.locationX = targetBound.left + (targetBound.width / 2) - (firstItemBound.width / 2);
            this.tween.target.locationY = targetBound.top+ (targetBound.height / 2) - (firstItemBound.height / 2);
            this.tween.target.scale = this.tween.target.width / firstItemBound.width;

            this.tween.sliderGapBetween = Math.round(this.DOM.topic_list.getBoundingClientRect().width * 0.022);

            let itemCount = this.tween.items.length;

            this.tween.scale = this.tween.target.width / firstItemBound.width;

            this.draggable.width = (itemCount - 2) * (this.tween.firstItem.width * this.tween.scale);
        },
        animate_openDetail(currentItem){
            this.timelines.detailContainerTimeline = new TimelineMax({
                onComplete: () => {
                    this.animate_selectItem(currentItem);
                }
            })
            .to(this.DOM.details_image, .5, {
                ease: Power3.easeOut,
                height: '100%'
            }, 0)
            .to(this.DOM.details_content, .5, {
                ease: Power3.easeOut,
                height: '100%'
            }, 0);
        },
        animate_closeDetail(){
            this.animate_closeCurrentItem(()=>{
                this.timelines.detailContainerTimeline = new TimelineMax({})
                    .to(this.DOM.details_image, .5, {
                        ease: Power3.easeOut,
                        height: '0%'
                    }, 0)
                    .to(this.DOM.details_content, .5, {
                        ease: Power3.easeOut,
                        height: '0%'
                    }, 0);
            });
        },
        animate_selectItem(currentItem){
            this.animate_closeCurrentItem(()=>{
                this.currentItem = currentItem;

                let currentImage           = this.DOM.items[this.currentItem].image;
                let contentImageFirst      = this.DOM.items[this.currentItem].content.querySelector('.content__image--first');
                let contentImageScale      = this.DOM.items[this.currentItem].content.querySelector('.content__image--first img');
                let contentImageSecond     = this.DOM.items[this.currentItem].content.querySelector('.content__image--second');
                let contentImageThird      = this.DOM.items[this.currentItem].content.querySelector('.content__image--third');
                let revealImage            = this.DOM.items[this.currentItem].content.querySelector('.content__image--reveal');
                let contentTitle           = this.DOM.items[this.currentItem].content.querySelector('.content--title');
                let contentText            = this.DOM.items[this.currentItem].content.querySelector('.content--description');

                this.timelines.detailContainerTimeline = new TimelineMax({})
                    .timeScale(1.5)
                    .set(contentImageScale, {
                        scale: 1.2,
                    }, 0)
                    .set(contentImageFirst, {
                        opacity: 1,
                    }, 0)
                    .to(currentImage, 1.5, {
                        ease: Power3.easeOut,
                        marginTop: '0',
                        opacity: 1,
                    }, 0)
                    .to(contentImageScale, 2, {
                        scale: 1,
                    }, 0)
                    .to(revealImage, 1, {
                        ease: Power3.easeOut,
                        x: '-100%'
                    }, 0)
                    .to(contentImageSecond, 1, {
                        ease: Power3.easeOut,
                        x: '-9vw',
                        y: '8vh',
                        opacity: 1
                    }, .75)
                    .to(contentImageThird, 1, {
                        ease: Power3.easeOut,
                        x: '9vw',
                        y: '10vh',
                        opacity: 1,
                    }, .75)
                    .to(contentTitle, 1, {
                        ease: Power3.easeOut,
                        opacity: 1,
                        y: -25,
                    }, 0)
                    .to(contentText, 1, {
                        ease: Power3.easeOut,
                        opacity: 1,
                        y: -25,
                    }, .25)
            });
        },
        animate_closeCurrentItem(onComplete){
            if(this.currentItem === null){
                onComplete();
            } else {
                let currentImage           = this.DOM.items[this.currentItem].image;
                let contentImageFirst      = this.DOM.items[this.currentItem].content.querySelector('.content__image--first');
                let contentImageScale      = this.DOM.items[this.currentItem].content.querySelector('.content__image--first img');
                let contentImageSecond     = this.DOM.items[this.currentItem].content.querySelector('.content__image--second');
                let contentImageThird      = this.DOM.items[this.currentItem].content.querySelector('.content__image--third');
                let revealImage            = this.DOM.items[this.currentItem].content.querySelector('.content__image--reveal');
                let contentTitle           = this.DOM.items[this.currentItem].content.querySelector('.content--title');
                let contentText            = this.DOM.items[this.currentItem].content.querySelector('.content--description');

                this.currentItem = null;

                this.timelines.detailContainerTimeline = new TimelineMax({
                    onComplete: ()=>{
                        onComplete();
                    }
                })
                    .timeScale(2)
                    .set(revealImage, {
                        ease: Power3.easeIn,
                        x: '+100%',
                    }, 0)
                    .to(currentImage, 1, {
                        ease: Power3.easeIn,
                        y: -100,
                        opacity: 0,
                    }, 0)
                    .to(revealImage, 1, {
                        ease: Power3.easeIn,
                        x: '0',
                    }, 0)
                    .to(contentText, 0.5, {
                        ease: Power3.easeIn,
                        opacity: 0,
                        y: -50,
                    }, .25)
                    .to(contentTitle, 0.5, {
                        ease: Power3.easeIn,
                        opacity: 0,
                        y: -50,
                    }, 0)
                    .to(contentImageSecond, 1, {
                        ease: Power3.easeOut,
                        x: 0,
                        y: 0,
                    }, 0)
                    .to(contentImageThird, 1, {
                        ease: Power3.easeOut,
                        x: 0,
                        y: 0,
                    }, 0)
                    .to(contentImageScale, 1, {
                        scale: 1.2,
                    }, 0)
                    .to(revealImage, 1, {
                        ease: Power3.easeOut,
                        x: '0%',
                    }, 0.5)
                    .set(currentImage, {clearProps: "all"}, 1.5)
                    .set(contentImageFirst, {clearProps: "all"}, 1.5)
                    .set(contentImageSecond, {clearProps: "all"}, 1.5)
                    .set(contentImageThird, {clearProps: "all"}, 1.5)
                    .set(contentImageScale, {clearProps: "all"}, 1.5)
                    .set(contentTitle, {clearProps: "all"}, 1.5)
                    .set(contentText, {clearProps: "all"}, 1.5)
                    .set(revealImage, {clearProps: "all"}, 1.5)
            }
        },
        animate_gridToSlider(){
            let zIndexCount = Object.keys(this.DOM.items).length;

            this.timelines.gridToSlider = new TimelineMax({
            });
            this.timelines.slideOutSlider = new TimelineMax({
                paused: true,
            });

            let count = 0;

            for(let i in this.tween.items){
                let el = this.DOM.items[this.tween.items[i].id].list_item;

                this.timelines.gridToSlider
                    .set(el,{
                        zIndex: zIndexCount--,
                    }, 0)
                    .to(el, 1, {
                        left: this.tween.target.locationX,
                        top: this.tween.target.locationY,
                    }, 0)
                    .to(el, .5, {
                        scale: this.tween.target.scale,
                    }, 1)
                    .add(this.timelines.slideOutSlider.play());


                if(count === 0) {
                    this.timelines.slideOutSlider.to(el, 1, {
                        x: (this.tween.target.width  * .8) * -1
                    }, 0);
                }
                else if(count === 1 || count === 2) {
                    this.timelines.slideOutSlider.to(el, 1, {
                        x: (this.tween.target.width  * (.2 + (count - 1))  + (this.tween.sliderGapBetween * count))
                    }, 0);
                } else {
                    this.timelines.slideOutSlider
                        .set(el, {
                            opacity: 0,
                            x: (this.tween.target.width  * (.2 + (count - 1)) + (this.tween.sliderGapBetween * count))
                        }, 0)
                        .to(el, .5, {
                            opacity: 1
                        }, .5)
                }
                count++;
            }
        },
        animate_sliderToGrid() {
            let self = this;
            TweenLite.to(this.DOM.topic_list, 0.5, {
                x: 0,
                onComplete(){
                    self.timelines.gridToSlider.reverse().timeScale(1.5);
                }
            });
        },
        dragStart(){
            this.scaleImagesIn();
        },
        dragEnd(){
            this.scaleImagesOut();
        },
        dragProgress(percent) {
            let x = -this.draggable.width * (percent / 100);
            if(x > 0) {
                TweenLite.to(this.DOM.topic_list, 0.5, {x: 0 +"px"});
            }
            else if(x < -1 * this.draggable.width) {
                TweenLite.to(this.DOM.topic_list, 0.5, {x: (-1 * this.draggable.width) +"px"});
            }
            else{
                TweenLite.to(this.DOM.topic_list, 0.5, {x: x +"px"});
            }
        },
        scaleImagesOut() {
            for (let item in this.DOM.items) {
                let el = this.DOM.items[item].list_item;
                TweenLite.to(el, 0.5, {scale:  this.tween.target.scale});
                let img = el.querySelector('.topic--background_image');
                TweenLite.to(img, 0.5, {scale: 1});
            }
        },
        scaleImagesIn() {
            for (let item in this.DOM.items) {
                let el = this.DOM.items[item].list_item;
                TweenLite.to(el, 0.5, {scale:  this.tween.target.scale * .9});
                let img = el.querySelector('.topic--background_image');
                TweenLite.to(img, 0.5, {scale: 1.3});
            }
        },
    },
}