<template>
    <div  class="active--filters">
        <div class="active--filters-item more"> {{ count }}</div>
    </div>
</template>

<script>
export default {
    name: 'ProductFilterActiveFilters',
    props: {
        visible: {
            type: Number,
            default: 6
        },
        filters: {
            type: Array,
        }
    },
    computed: {
        count() {
            return this.filters.length;
        },
    }
}
</script>

<style lang="scss" scoped>
    .active--filters {
        display: flex;
        flex-flow: row wrap;
    }
    .active--filters-item {
        background-color: rgba(255,255,255,.1);
        backdrop-filter: blur(20px);
        font-size: 2vw;
        padding: .5vh 3vw;
        border-radius: 2vh;
        margin: .5vh;
        border: 5px solid transparent;
        &.more {
            background-color: transparent;
            border-color: rgba(255,255,255,.1);
        }
    }
</style>