module.exports = {
    getPairs: (item) => {
        if(item.count == 0) {
            return 0;
        }
        const pairs = item.range.filter(Boolean);
        const add = (a, b) => parseInt(a,10) + parseInt(b, 10);

        return pairs.reduce(add) * item.count;
    },
    getPairsDefault: (item) => {
        const pairs = item.range.filter(Boolean);
        const add = (a, b) => parseInt(a,10) + parseInt(b, 10);

        return pairs.reduce(add);
    }
}