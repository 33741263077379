export default class GridConstructor {
    constructor(options = {}) {
        this.portrait = {
            1: '"i-1"',
            2: '"i-1" "i-2"',
            3: '"i-1" "i-2" "i-3"',
            4: '"i-1" "i-2" "i-3" "i-4"',
            5: '"i-1 i-2" "i-3 i-3" "i-4 i-5"',
            6: '"i-1 i-2" "i-3 i-4" "i-5 i-6"',
            7: '"i-1 i-2" "i-3 i-3" "i-4 i-5" "i-6 i-7"',
            8: '"i-1 i-2" "i-3 i-3" "i-4 i-5" "i-6 i-6" "i-7 i-8"',
            9: '"i-1 i-2" "i-3 i-3" "i-4 i-5" "i-6 i-6" "i-7 i-8" "i-9 i-9"',
            10: '"i-1 i-2" "i-3 i-3" "i-4 i-5" "i-6 i-6" "i-7 i-8" "i-9 i-10"',
            11: '"i-1 i-2 i-2" "i-3 i-3 i-3" "i-4 i-5 i-6" "i-7 i-7 i-7" "i-8 i-8 i-9" "i-10 i-11 i-11"',
            12: '"i-1 i-2 i-2" "i-3 i-3 i-4" "i-5 i-6 i-7" "i-8 i-8 i-8" "i-9 i-9 i-10" "i-11 i-12 i-12"',
            13: '"i-1 i-2 i-2" "i-3 i-3 i-4" "i-5 i-6 i-7" "i-8 i-9 i-9" "i-10 i-10 i-11" "i-12 i-13 i-13"',
            14: '"i-1 i-2 i-2" "i-3 i-3 i-4" "i-5 i-6 i-7" "i-8 i-9 i-9" "i-10 i-10 i-11" "i-12 i-13 i-14"',
            15: '"i-1 i-2 i-3" "i-4 i-4 i-5" "i-6 i-7 i-8" "i-9 i-10 i-10" "i-11 i-11 i-12" "i-13 i-14 i-15"',
            16: '"i-1 i-2 i-3" "i-4 i-5 i-6" "i-7 i-8 i-9" "i-10 i-11 i-11" "i-12 i-12 i-13" "i-14 i-15 i-16"',
            17: '"i-1 i-2 i-3" "i-4 i-5 i-6" "i-7 i-8 i-9" "i-10 i-11 i-11" "i-12 i-13 i-14" "i-15 i-16 i-17"',
            18: '"i-1 i-2 i-3" "i-4 i-5 i-6" "i-7 i-8 i-9" "i-10 i-11 i-12" "i-13 i-14 i-15" "i-16 i-17 i-18"',
            19: '"i-1 i-2 i-2 i-3" "i-4 i-4 i-5 i-5" "i-6 i-6 i-7 i-8" "i-9 i-10 i-10 i-11" "i-12 i-12 i-13 i-13" "i-14 i-15 i-16 i-16" "i-17 i-17 i-18 i-19"',
            20: '"i-1 i-2 i-3 i-3" "i-4 i-5 i-6 i-6" "i-7 i-7 i-8 i-9" "i-10 i-11 i-11 i-12" "i-13 i-13 i-14 i-14" "i-15 i-16 i-17 i-17" "i-18 i-18 i-19 i-20"',
            21: '"i-1 i-2 i-3 i-3" "i-4 i-5 i-6 i-6" "i-7 i-7 i-8 i-9" "i-10 i-11 i-11 i-12" "i-13 i-13 i-14 i-15" "i-16 i-17 i-18 i-18" "i-19 i-19 i-20 i-21"',
            22: '"i-1 i-2 i-3 i-3" "i-4 i-5 i-6 i-6" "i-7 i-7 i-8 i-9" "i-10 i-11 i-11 i-12" "i-13 i-14 i-15 i-16" "i-17 i-18 i-19 i-19" "i-20 i-20 i-21 i-22"',
            23: '"i-1 i-2 i-3 i-3" "i-4 i-5 i-6 i-7" "i-8 i-8 i-9 i-10" "i-11 i-12 i-12 i-13" "i-14 i-15 i-16 i-17" "i-18 i-19 i-20 i-20" "i-21 i-21 i-22 i-23"',
            24: '"i-1 i-2 i-3 i-3" "i-4 i-5 i-6 i-7" "i-8 i-8 i-9 i-10" "i-11 i-12 i-12 i-13" "i-14 i-15 i-16 i-17" "i-18 i-19 i-20 i-20" "i-21 i-22 i-23 i-24"',
            25: '"i-1 i-2 i-3 i-3" "i-4 i-5 i-6 i-7" "i-8 i-8 i-9 i-10" "i-11 i-12 i-13 i-14" "i-15 i-16 i-17 i-18" "i-19 i-20 i-21 i-21" "i-22 i-23 i-24 i-25"',
            26: '"i-1 i-2 i-3 i-4" "i-5 i-6 i-7 i-8" "i-9 i-9 i-10 i-11" "i-12 i-13 i-14 i-15" "i-16 i-17 i-18 i-19" "i-20 i-21 i-22 i-22" "i-23 i-24 i-25 i-26"',
            27: '"i-1 i-2 i-3 i-4" "i-5 i-6 i-7 i-8" "i-9 i-10 i-11 i-12" "i-13 i-14 i-15 i-16" "i-17 i-18 i-19 i-20" "i-21 i-22 i-23 i-23" "i-24 i-25 i-26 i-27"',
            28: '"i-1 i-2 i-3 i-4" "i-5 i-6 i-7 i-8" "i-9 i-10 i-11 i-12" "i-13 i-14 i-15 i-16" "i-17 i-18 i-19 i-20" "i-21 i-22 i-23 i-24" "i-25 i-26 i-27 i-28"',
            29: '"i-1 i-2 i-2 i-3 i-3" "i-4 i-4 i-5 i-6 i-6" "i-7 i-7 i-8 i-8 i-9" "i-10 i-11 i-11 i-12 i-12" "i-13 i-14 i-15 i-15 i-16" "i-17 i-18 i-18 i-19 i-20" "i-21 i-21 i-22 i-22 i-23" "i-24 i-24 i-25 i-26 i-26" "i-27 i-28 i-28 i-29 i-29"',
            30: '"i-1 i-2 i-2 i-3 i-3" "i-4 i-4 i-5 i-6 i-6" "i-7 i-7 i-8 i-8 i-9" "i-10 i-11 i-11 i-12 i-12" "i-13 i-14 i-15 i-15 i-16" "i-17 i-18 i-18 i-19 i-20" "i-21 i-21 i-22 i-22 i-23" "i-24 i-24 i-25 i-26 i-26" "i-27 i-28 i-28 i-29 i-30"',
            31: '"i-1 i-2 i-3 i-3 i-4" "i-5 i-5 i-6 i-7 i-7" "i-8 i-8 i-9 i-9 i-10" "i-11 i-12 i-12 i-13 i-13" "i-14 i-15 i-16 i-16 i-17" "i-18 i-19 i-19 i-20 i-21" "i-22 i-22 i-23 i-23 i-24" "i-25 i-25 i-26 i-27 i-27" "i-28 i-29 i-29 i-30 i-31"',
            32: '"i-1 i-2 i-3 i-3 i-4" "i-5 i-5 i-6 i-7 i-7" "i-8 i-8 i-9 i-9 i-10" "i-11 i-12 i-12 i-13 i-13" "i-14 i-15 i-16 i-16 i-17" "i-18 i-19 i-19 i-20 i-21" "i-22 i-23 i-24 i-24 i-25" "i-26 i-26 i-27 i-28 i-28" "i-29 i-30 i-30 i-31 i-32"',
            33: '"i-1 i-2 i-3 i-3 i-4" "i-5 i-5 i-6 i-7 i-7" "i-8 i-8 i-9 i-9 i-10" "i-11 i-12 i-12 i-13 i-13" "i-14 i-15 i-16 i-16 i-17" "i-18 i-19 i-19 i-20 i-21" "i-22 i-23 i-24 i-24 i-25" "i-26 i-26 i-27 i-28 i-28" "i-29 i-30 i-31 i-32 i-33"',
            34: '"i-1 i-2 i-3 i-3 i-4" "i-5 i-5 i-6 i-7 i-7" "i-8 i-8 i-9 i-9 i-10" "i-11 i-12 i-12 i-13 i-13" "i-14 i-15 i-16 i-16 i-17" "i-18 i-19 i-19 i-20 i-21" "i-22 i-23 i-24 i-24 i-25" "i-26 i-26 i-27 i-28 i-28" "i-29 i-29 i-30 i-30 i-31" "i-32 i-33 i-33 i-34 i-34"',
            35: '"i-1 i-2 i-3 i-3 i-4" "i-5 i-5 i-6 i-7 i-7" "i-8 i-8 i-9 i-9 i-10" "i-11 i-12 i-12 i-13 i-13" "i-14 i-15 i-16 i-16 i-17" "i-18 i-19 i-19 i-20 i-21" "i-22 i-23 i-24 i-24 i-25" "i-26 i-26 i-27 i-28 i-28" "i-29 i-29 i-30 i-30 i-31" "i-32 i-33 i-33 i-34 i-35"',
            36: '"i-1 i-2 i-3 i-3 i-4" "i-5 i-6 i-7 i-8 i-8" "i-9 i-9 i-10 i-10 i-11" "i-12 i-13 i-13 i-14 i-14" "i-15 i-16 i-17 i-17 i-18" "i-19 i-20 i-20 i-21 i-22" "i-23 i-24 i-25 i-25 i-26" "i-27 i-27 i-28 i-29 i-29" "i-30 i-30 i-31 i-31 i-32" "i-33 i-34 i-34 i-35 i-36"',
            37: '"i-1 i-2 i-3 i-3 i-4" "i-5 i-6 i-7 i-8 i-8" "i-9 i-9 i-10 i-10 i-11" "i-12 i-13 i-13 i-14 i-14" "i-15 i-16 i-17 i-17 i-18" "i-19 i-20 i-20 i-21 i-22" "i-23 i-24 i-25 i-25 i-26" "i-27 i-27 i-28 i-29 i-29" "i-30 i-30 i-31 i-32 i-33" "i-34 i-35 i-35 i-36 i-37"',
            38: '"i-1 i-2 i-3 i-3 i-4" "i-5 i-6 i-7 i-8 i-8" "i-9 i-9 i-10 i-11 i-12" "i-13 i-14 i-14 i-15 i-15" "i-16 i-17 i-18 i-18 i-19" "i-20 i-21 i-21 i-22 i-23" "i-24 i-25 i-26 i-26 i-27" "i-28 i-28 i-29 i-30 i-30" "i-31 i-31 i-32 i-33 i-34" "i-35 i-36 i-36 i-37 i-38"',
            39: '"i-1 i-2 i-3 i-3 i-4" "i-5 i-6 i-7 i-8 i-8" "i-9 i-9 i-10 i-11 i-12" "i-13 i-14 i-14 i-15 i-16" "i-17 i-18 i-19 i-19 i-20" "i-21 i-22 i-22 i-23 i-24" "i-25 i-26 i-27 i-27 i-28" "i-29 i-29 i-30 i-31 i-31" "i-32 i-32 i-33 i-34 i-35" "i-36 i-37 i-37 i-38 i-39"',
            40: '"i-1 i-2 i-3 i-3 i-4" "i-5 i-6 i-7 i-8 i-8" "i-9 i-9 i-10 i-11 i-12" "i-13 i-14 i-14 i-15 i-16" "i-17 i-18 i-19 i-19 i-20" "i-21 i-22 i-22 i-23 i-24" "i-25 i-26 i-27 i-27 i-28" "i-29 i-30 i-31 i-32 i-32" "i-33 i-33 i-34 i-35 i-36" "i-37 i-38 i-38 i-39 i-40"',
            41: '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-9" "i-10 i-10 i-11 i-12 i-13" "i-14 i-15 i-15 i-16 i-17" "i-18 i-19 i-20 i-20 i-21" "i-22 i-23 i-23 i-24 i-25" "i-26 i-27 i-28 i-28 i-29" "i-30 i-31 i-32 i-33 i-33" "i-34 i-34 i-35 i-36 i-37" "i-38 i-39 i-39 i-40 i-41"',
            42: '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-9" "i-10 i-10 i-11 i-12 i-13" "i-14 i-15 i-15 i-16 i-17" "i-18 i-19 i-20 i-20 i-21" "i-22 i-23 i-23 i-24 i-25" "i-26 i-27 i-28 i-28 i-29" "i-30 i-31 i-32 i-33 i-33" "i-34 i-34 i-35 i-36 i-37" "i-38 i-39 i-40 i-41 i-42"',
            43: '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-9" "i-10 i-10 i-11 i-12 i-13" "i-14 i-15 i-15 i-16 i-17" "i-18 i-19 i-20 i-20 i-21" "i-22 i-23 i-24 i-25 i-26" "i-27 i-28 i-29 i-29 i-30" "i-31 i-32 i-33 i-34 i-34" "i-35 i-35 i-36 i-37 i-38" "i-39 i-40 i-41 i-42 i-43"',
            44: '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-9" "i-10 i-10 i-11 i-12 i-13" "i-14 i-15 i-15 i-16 i-17" "i-18 i-19 i-20 i-20 i-21" "i-22 i-23 i-24 i-25 i-26" "i-27 i-28 i-29 i-29 i-30" "i-31 i-32 i-33 i-34 i-35" "i-36 i-36 i-37 i-38 i-39" "i-40 i-41 i-42 i-43 i-44"',
            45: '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-9" "i-10 i-11 i-12 i-13 i-14" "i-15 i-16 i-16 i-17 i-18" "i-19 i-20 i-21 i-21 i-22" "i-23 i-24 i-25 i-26 i-27" "i-28 i-29 i-30 i-30 i-31" "i-32 i-33 i-34 i-35 i-36" "i-37 i-37 i-38 i-39 i-40" "i-41 i-42 i-43 i-44 i-45"',
            46: '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-9" "i-10 i-11 i-12 i-13 i-14" "i-15 i-16 i-16 i-17 i-18" "i-19 i-20 i-21 i-22 i-23" "i-24 i-25 i-26 i-27 i-28" "i-29 i-30 i-31 i-31 i-32" "i-33 i-34 i-35 i-36 i-37" "i-38 i-38 i-39 i-40 i-41" "i-42 i-43 i-44 i-45 i-46"',
            47: '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-9" "i-10 i-11 i-12 i-13 i-14" "i-15 i-16 i-16 i-17 i-18" "i-19 i-20 i-21 i-22 i-23" "i-24 i-25 i-26 i-27 i-28" "i-29 i-30 i-31 i-31 i-32" "i-33 i-34 i-35 i-36 i-37" "i-38 i-39 i-40 i-41 i-42" "i-43 i-44 i-45 i-46 i-47"',
            48: '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-13 i-14 i-15" "i-16 i-17 i-17 i-18 i-19" "i-20 i-21 i-22 i-23 i-24" "i-25 i-26 i-27 i-28 i-29" "i-30 i-31 i-32 i-32 i-33" "i-34 i-35 i-36 i-37 i-38" "i-39 i-40 i-41 i-42 i-43" "i-44 i-45 i-46 i-47 i-48"',
            49: '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-13 i-14 i-15" "i-16 i-17 i-17 i-18 i-19" "i-20 i-21 i-22 i-23 i-24" "i-25 i-26 i-27 i-28 i-29" "i-30 i-31 i-32 i-33 i-34" "i-35 i-36 i-37 i-38 i-39" "i-40 i-41 i-42 i-43 i-44" "i-45 i-46 i-47 i-48 i-49"',
            50: '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-13 i-14 i-15" "i-16 i-17 i-18 i-19 i-20" "i-21 i-22 i-23 i-24 i-25" "i-26 i-27 i-28 i-29 i-30" "i-31 i-32 i-33 i-34 i-35" "i-36 i-37 i-38 i-39 i-40" "i-41 i-42 i-43 i-44 i-45" "i-46 i-47 i-48 i-49 i-50"'
        }
        this.landscape = {
            1: '"i-1"',
            2: '"i-1 i-2"',
            3: '"i-1 i-2 i-3"',
            4: '"i-1 i-2" "i-3 i-4"',
            5: '"i-1 i-2 i-3" "i-4 i-5 i-5"',
            6: '"i-1 i-2 i-3" "i-4 i-5 i-6"',
            7: '"i-1 i-2 i-2 i-3 i-4" "i-5 i-5 i-6 i-7 i-7"',
            8: '"i-1 i-2 i-3 i-4" "i-5 i-6 i-7 i-8"',
            9: '"i-1 i-2 i-2 i-3" "i-4 i-4 i-5 i-6" "i-7 i-8 i-9 i-9"',
            10: '"i-1 i-2 i-2 i-3" "i-4 i-5 i-6 i-7" "i-8 i-9 i-10 i-10"',
            11: '"i-1 i-2 i-2 i-3" "i-4 i-5 i-6 i-7" "i-8 i-9 i-10 i-11"',
            12: '"i-1 i-2 i-3 i-4" "i-5 i-6 i-7 i-8" "i-9 i-10 i-11 i-12"',
            13: '"i-1 i-2 i-3 i-4 i-4 i-5" "i-6 i-6 i-7 i-8 i-8 i-9" "i-10 i-11 i-11 i-12 i-13 i-13"',
            14: '"i-1 i-2 i-3 i-4 i-4 i-5" "i-6 i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-12 i-13 i-14 i-14"',
            15: '"i-1 i-2 i-3 i-4 i-4 i-5" "i-6 i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-13 i-14 i-15 i-15"',
            16: '"i-1 i-2 i-3 i-4 i-4 i-5" "i-6 i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-13 i-14 i-15 i-16"',
            17: '"i-1 i-2 i-3 i-4 i-5 i-6" "i-7 i-7 i-8 i-9 i-10 i-11" "i-12 i-13 i-14 i-15 i-16 i-17"',
            18: '"i-1 i-2 i-3 i-4 i-5 i-6" "i-7 i-8 i-9 i-10 i-11 i-12" "i-13 i-14 i-15 i-16 i-17 i-18"',
            19: '"i-1 i-2 i-3 i-4 i-5 i-5 i-6" "i-7 i-8 i-8 i-9 i-10 i-11 i-12" "i-13 i-14 i-15 i-16 i-17 i-18 i-19"',
            20: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7" "i-8 i-9 i-9 i-10 i-11 i-12 i-13" "i-14 i-15 i-16 i-17 i-18 i-19 i-20"',
            21: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7" "i-8 i-9 i-10 i-11 i-12 i-13 i-14" "i-15 i-16 i-17 i-18 i-19 i-20 i-21"',
            22: '"i-1 i-1 i-2 i-3 i-4 i-4 i-5" "i-6 i-7 i-7 i-8 i-9 i-10 i-11" "i-12 i-12 i-13 i-14 i-15 i-16 i-16" "i-17 i-18 i-19 i-19 i-20 i-21 i-22"',
            23: '"i-1 i-2 i-3 i-4 i-5 i-5 i-6" "i-7 i-8 i-8 i-9 i-10 i-11 i-12" "i-13 i-13 i-14 i-15 i-16 i-17 i-17" "i-18 i-19 i-20 i-20 i-21 i-22 i-23"',
            24: '"i-1 i-2 i-3 i-4 i-5 i-5 i-6" "i-7 i-8 i-8 i-9 i-10 i-11 i-12" "i-13 i-14 i-15 i-16 i-17 i-18 i-18" "i-19 i-20 i-21 i-21 i-22 i-23 i-24"',
            25: '"i-1 i-2 i-3 i-4 i-5 i-5 i-6" "i-7 i-8 i-8 i-9 i-10 i-11 i-12" "i-13 i-14 i-15 i-16 i-17 i-18 i-18" "i-19 i-20 i-21 i-22 i-23 i-24 i-25"',
            26: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7" "i-8 i-9 i-9 i-10 i-11 i-12 i-13" "i-14 i-15 i-16 i-17 i-18 i-19 i-19" "i-20 i-21 i-22 i-23 i-24 i-25 i-26"',
            27: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7" "i-8 i-9 i-9 i-10 i-11 i-12 i-13" "i-14 i-15 i-16 i-17 i-18 i-19 i-20" "i-21 i-22 i-23 i-24 i-25 i-26 i-27"',
            28: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7" "i-8 i-9 i-10 i-11 i-12 i-13 i-14" "i-15 i-16 i-17 i-18 i-19 i-20 i-21" "i-22 i-23 i-24 i-25 i-26 i-27 i-28"',
            29: '"i-1 i-2 i-3 i-4 i-5 i-6 i-6 i-7 i-8" "i-9 i-10 i-10 i-11 i-12 i-12 i-13 i-14 i-14" "i-15 i-15 i-16 i-17 i-18 i-19 i-20 i-20 i-21" "i-22 i-23 i-24 i-24 i-25 i-26 i-27 i-28 i-29"',
            30: '"i-1 i-2 i-3 i-4 i-5 i-6 i-6 i-7 i-8" "i-9 i-10 i-10 i-11 i-12 i-12 i-13 i-14 i-15" "i-16 i-16 i-17 i-18 i-19 i-20 i-21 i-21 i-22" "i-23 i-24 i-25 i-25 i-26 i-27 i-28 i-29 i-30"',
            31: '"i-1 i-2 i-3 i-4 i-5 i-6 i-6 i-7 i-8" "i-9 i-10 i-10 i-11 i-12 i-13 i-14 i-15 i-16" "i-17 i-17 i-18 i-19 i-20 i-21 i-22 i-22 i-23" "i-24 i-25 i-26 i-26 i-27 i-28 i-29 i-30 i-31"',
            32: '"i-1 i-2 i-3 i-4 i-5 i-6 i-6 i-7 i-8" "i-9 i-10 i-10 i-11 i-12 i-13 i-14 i-15 i-16" "i-17 i-18 i-19 i-20 i-21 i-22 i-23 i-23 i-24" "i-25 i-26 i-27 i-27 i-28 i-29 i-30 i-31 i-32"',
            33: '"i-1 i-2 i-3 i-4 i-5 i-6 i-6 i-7 i-8" "i-9 i-10 i-10 i-11 i-12 i-13 i-14 i-15 i-16" "i-17 i-18 i-19 i-20 i-21 i-22 i-23 i-23 i-24" "i-25 i-26 i-27 i-28 i-29 i-30 i-31 i-32 i-33"',
            34: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7 i-8 i-9" "i-10 i-11 i-11 i-12 i-13 i-14 i-15 i-16 i-17" "i-18 i-19 i-20 i-21 i-22 i-23 i-24 i-24 i-25" "i-26 i-27 i-28 i-29 i-30 i-31 i-32 i-33 i-34"',
            35: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7 i-8 i-9" "i-10 i-11 i-11 i-12 i-13 i-14 i-15 i-16 i-17" "i-18 i-19 i-20 i-21 i-22 i-23 i-24 i-25 i-26" "i-27 i-28 i-29 i-30 i-31 i-32 i-33 i-34 i-35"',
            36: '"i-1 i-2 i-2 i-3 i-4 i-4 i-5 i-6 i-6 i-7" "i-8 i-8 i-9 i-9 i-10 i-11 i-12 i-12 i-13 i-14" "i-15 i-16 i-17 i-18 i-18 i-19 i-20 i-21 i-22 i-22" "i-23 i-23 i-24 i-24 i-25 i-26 i-26 i-27 i-28 i-29" "i-30 i-31 i-31 i-32 i-33 i-34 i-35 i-35 i-36 i-36"',
            37: '"i-1 i-2 i-2 i-3 i-4 i-5 i-6 i-7 i-7 i-8" "i-9 i-9 i-10 i-10 i-11 i-12 i-13 i-13 i-14 i-15" "i-16 i-17 i-18 i-19 i-19 i-20 i-21 i-22 i-23 i-23" "i-24 i-24 i-25 i-25 i-26 i-27 i-27 i-28 i-29 i-30" "i-31 i-32 i-32 i-33 i-34 i-35 i-36 i-36 i-37 i-37"',
            38: '"i-1 i-2 i-2 i-3 i-4 i-5 i-6 i-7 i-7 i-8" "i-9 i-10 i-11 i-11 i-12 i-13 i-14 i-14 i-15 i-16" "i-17 i-18 i-19 i-20 i-20 i-21 i-22 i-23 i-24 i-24" "i-25 i-25 i-26 i-26 i-27 i-28 i-28 i-29 i-30 i-31" "i-32 i-33 i-33 i-34 i-35 i-36 i-37 i-37 i-38 i-38"',
            39: '"i-1 i-2 i-2 i-3 i-4 i-5 i-6 i-7 i-7 i-8" "i-9 i-10 i-11 i-11 i-12 i-13 i-14 i-14 i-15 i-16" "i-17 i-18 i-19 i-20 i-20 i-21 i-22 i-23 i-24 i-24" "i-25 i-25 i-26 i-26 i-27 i-28 i-28 i-29 i-30 i-31" "i-32 i-33 i-33 i-34 i-35 i-36 i-37 i-37 i-38 i-39"',
            40: '"i-1 i-2 i-2 i-3 i-4 i-5 i-6 i-7 i-7 i-8" "i-9 i-10 i-11 i-11 i-12 i-13 i-14 i-14 i-15 i-16" "i-17 i-18 i-19 i-20 i-20 i-21 i-22 i-23 i-24 i-24" "i-25 i-25 i-26 i-27 i-28 i-29 i-29 i-30 i-31 i-32" "i-33 i-34 i-34 i-35 i-36 i-37 i-38 i-38 i-39 i-40"',
            41: '"i-1 i-2 i-2 i-3 i-4 i-5 i-6 i-7 i-7 i-8" "i-9 i-10 i-11 i-11 i-12 i-13 i-14 i-14 i-15 i-16" "i-17 i-18 i-19 i-20 i-20 i-21 i-22 i-23 i-24 i-24" "i-25 i-26 i-27 i-28 i-29 i-30 i-30 i-31 i-32 i-33" "i-34 i-35 i-35 i-36 i-37 i-38 i-39 i-39 i-40 i-41"',
            42: '"i-1 i-2 i-2 i-3 i-4 i-5 i-6 i-7 i-7 i-8" "i-9 i-10 i-11 i-11 i-12 i-13 i-14 i-15 i-16 i-17" "i-18 i-19 i-20 i-21 i-21 i-22 i-23 i-24 i-25 i-25" "i-26 i-27 i-28 i-29 i-30 i-31 i-31 i-32 i-33 i-34" "i-35 i-36 i-36 i-37 i-38 i-39 i-40 i-40 i-41 i-42"',
            43: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7 i-8 i-8 i-9" "i-10 i-11 i-12 i-12 i-13 i-14 i-15 i-16 i-17 i-18" "i-19 i-20 i-21 i-22 i-22 i-23 i-24 i-25 i-26 i-26" "i-27 i-28 i-29 i-30 i-31 i-32 i-32 i-33 i-34 i-35" "i-36 i-37 i-37 i-38 i-39 i-40 i-41 i-41 i-42 i-43"',
            44: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7 i-8 i-8 i-9" "i-10 i-11 i-12 i-12 i-13 i-14 i-15 i-16 i-17 i-18" "i-19 i-20 i-21 i-22 i-22 i-23 i-24 i-25 i-26 i-27" "i-28 i-29 i-30 i-31 i-32 i-33 i-33 i-34 i-35 i-36" "i-37 i-38 i-38 i-39 i-40 i-41 i-42 i-42 i-43 i-44"',
            45: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7 i-8 i-8 i-9" "i-10 i-11 i-12 i-12 i-13 i-14 i-15 i-16 i-17 i-18" "i-19 i-20 i-21 i-22 i-22 i-23 i-24 i-25 i-26 i-27" "i-28 i-29 i-30 i-31 i-32 i-33 i-33 i-34 i-35 i-36" "i-37 i-38 i-38 i-39 i-40 i-41 i-42 i-43 i-44 i-45"',
            46: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7 i-8 i-8 i-9" "i-10 i-11 i-12 i-12 i-13 i-14 i-15 i-16 i-17 i-18" "i-19 i-20 i-21 i-22 i-23 i-24 i-25 i-26 i-27 i-28" "i-29 i-30 i-31 i-32 i-33 i-34 i-34 i-35 i-36 i-37" "i-38 i-39 i-39 i-40 i-41 i-42 i-43 i-44 i-45 i-46"',
            47: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7 i-8 i-8 i-9" "i-10 i-11 i-12 i-12 i-13 i-14 i-15 i-16 i-17 i-18" "i-19 i-20 i-21 i-22 i-23 i-24 i-25 i-26 i-27 i-28" "i-29 i-30 i-31 i-32 i-33 i-34 i-34 i-35 i-36 i-37" "i-38 i-39 i-40 i-41 i-42 i-43 i-44 i-45 i-46 i-47"',
            48: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-13 i-13 i-14 i-15 i-16 i-17 i-18 i-19" "i-20 i-21 i-22 i-23 i-24 i-25 i-26 i-27 i-28 i-29" "i-30 i-31 i-32 i-33 i-34 i-35 i-35 i-36 i-37 i-38" "i-39 i-40 i-41 i-42 i-43 i-44 i-45 i-46 i-47 i-48"',
            49: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-13 i-13 i-14 i-15 i-16 i-17 i-18 i-19" "i-20 i-21 i-22 i-23 i-24 i-25 i-26 i-27 i-28 i-29" "i-30 i-31 i-32 i-33 i-34 i-35 i-36 i-37 i-38 i-39" "i-40 i-41 i-42 i-43 i-44 i-45 i-46 i-47 i-48 i-49"',
            50: '"i-1 i-2 i-3 i-4 i-5 i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-13 i-14 i-15 i-16 i-17 i-18 i-19 i-20" "i-21 i-22 i-23 i-24 i-25 i-26 i-27 i-28 i-29 i-30" "i-31 i-32 i-33 i-34 i-35 i-36 i-37 i-38 i-39 i-40" "i-41 i-42 i-43 i-44 i-45 i-46 i-47 i-48 i-49 i-50"'
        }
    }

    getPortrait() {
        return this.portrait
    }

    getLandscape() {
        return this.landscape
    }
}
