import MathUtils from './utils/math.js';
import {TweenMax, TimelineMax, Power2, Power3} from 'gsap';
import Slide from './Slide.js';

export default class Slideshow {
	constructor(vuecomponent) {
		this.vuecomponent = vuecomponent;
		this.DOM = {el: vuecomponent.$refs.slideshow};
		// Title Items
		this.DOM.titlesWrap = this.DOM.el.querySelector('.titles-wrap');
		this.DOM.titlesInner = this.DOM.titlesWrap.querySelector('.grid--titles');
		this.DOM.titles = [...this.DOM.titlesInner.querySelectorAll('.grid__item--title')];
		this.DOM.Container = this.DOM.el.querySelector('.grid--slideshow');
		this.DOM.Side = this.DOM.el.querySelector('.grid--side');
		this.skipAnimation = false;
		// Slides Items
		this.slides = [];
		[...this.DOM.el.querySelectorAll('.grid__item--slide')].forEach((slide, pos) => this.slides.push(new Slide(slide, this.DOM.titles[pos])));
		// Slides Total
		this.slidesTotal = this.slides.length;
		//if (this.slidesTotal < 4) return;	// ???
		this.center = 0;

		// Grid Areas
		this.DOM.interaction = {
			center: this.DOM.el.querySelector('.grid__item--center'),
		};

		this.setVisibleSlides();
		this.calculateGap();
		this.initEvents();
	}

	setVisibleSlides() {
		this.centerSlide = this.slides[this.center];
		this.rightSlide = this.slides[this.center + 1 <= this.slidesTotal - 1 ? this.center + 1 : 0];
		this.leftSlide = this.slides[this.center - 1 >= 0 ? this.center - 1 : this.slidesTotal - 1];
		this.centerSlide.setCenter();
		this.rightSlide.setRight();
		this.leftSlide.setLeft();

		this.vuecomponent.updateCenterItem(this.centerSlide.DOM.id);
	}

	// Calculate Gaps
	calculateGap() {
		const s1 = this.slides[0].DOM.el.getBoundingClientRect();
		const s2 = this.slides[1].DOM.el.getBoundingClientRect();
		this.gap = MathUtils.distance(s1.left + s1.width / 2, s2.left + s2.width / 2, s1.top + s1.height / 2, s2.top + s2.height / 2);
	}

	// Initialize Events
	initEvents() {
		this.clickCenterFn = () => this.vuecomponent.openDetail();
		this.DOM.interaction.center.addEventListener('click', this.clickCenterFn);

		this.resizeFn = () => this.calculateGap();
		window.addEventListener('resize', this.resizeFn);
	}
	setSkipAnimation(state) {
		this.skipAnimation = state;
	}

	navigate(direction) {
		if (this.isAnimating) {
			return false;
		}

		this.vuecomponent.updateAnimating(true);
		this.isAnimating = true;

		const upcomingPos = direction === 'right' ?
			this.center < this.slidesTotal - 2 ? this.center + 2 : Math.abs(this.slidesTotal - 2 - this.center) :
			this.center >= 2 ? this.center - 2 : Math.abs(this.slidesTotal - 2 + this.center);

		// Update Current
		this.center = direction === 'right' ?
			this.center < this.slidesTotal - 1 ? this.center + 1 : 0 :
			this.center > 0 ? this.center - 1 : this.slidesTotal - 1;

		this.upcomingSlide = this.slides[upcomingPos];
		this.upcomingTitle = this.upcomingSlide.DOM.title;

		// Set Position
		TweenMax.set(this.upcomingSlide.DOM.el, {
			x: direction === 'right' ? this.gap * 2 : -1 * this.gap * 2,
			opacity: 1
		});
		TweenMax.set(this.upcomingTitle, {
			x: direction === 'right' ? this.gap * 2 : -1 * this.gap * 2,
			opacity: 1
		});

		const movingSlides = [this.upcomingSlide, this.centerSlide, this.rightSlide, this.leftSlide];

		// Animations, Right
		if (direction === 'right') {
			TweenMax.to([this.centerSlide.DOM.imgWrap, this.leftSlide.DOM.imgWrap, this.upcomingSlide.DOM.imgWrap], 1.5, {
				ease: Power3.easeInOut,
				scale: '.8',
				filter: 'blur(4px)',
			});

			TweenMax.to([this.centerSlide.DOM.info, this.leftSlide.DOM.info, this.upcomingSlide.DOM.info], 1, {
				ease: Power3.easeInOut,
				opacity: 0,
				delay: 0.2,
			});

			TweenMax.to(this.rightSlide.DOM.imgWrap, 1.5, {
				ease: Power2.easeInOut,
				scale: '1.4',
				filter: 'blur(0px)',
				delay: 0.1,
			});

			TweenMax.to(this.rightSlide.DOM.info, 1, {
				ease: Power3.easeInOut,
				opacity: 1,
				delay: 0.8,
			});
		}

		// Animations, Left
		if (direction === 'left') {
			TweenMax.to([this.centerSlide.DOM.imgWrap, this.rightSlide.DOM.imgWrap, this.upcomingSlide.DOM.imgWrap], 1.5, {
				ease: Power3.easeInOut,
				scale: '.8',
				filter: 'blur(4px)',
			});

			TweenMax.to([this.centerSlide.DOM.info, this.rightSlide.DOM.info, this.upcomingSlide.DOM.info], 1, {
				ease: Power3.easeInOut,
				opacity: 0,
				delay: 0.2,
			});

			TweenMax.to(this.leftSlide.DOM.imgWrap, 1.5, {
				ease: Power2.easeInOut,
				scale: '1.4',
				filter: 'blur(0px)',
				delay: 0.1,
			});

			TweenMax.to(this.leftSlide.DOM.info, 1, {
				ease: Power3.easeInOut,
				opacity: 1,
				delay: 0.8,
			});
		}

		let promises = [];
		movingSlides.forEach(slide => promises.push(slide.move(direction === 'right' ? 'left' : 'right', this.gap, this.skipAnimation)));
		Promise.all(promises).then(() => {

			// Update Classes / Reset Styles
			movingSlides.forEach(slide => slide.reset());

			// Set Classes / Styles
			this.setVisibleSlides();
			this.isAnimating = false;
			this.setSkipAnimation(false);
			this.vuecomponent.updateAnimating(false);
		}).catch(err => { console.log(err) });
	}
}