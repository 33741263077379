export default class VantaConstructor {
    constructor(options = {}) {
        this.mouseControls = (options.mouseControls) ? options.mouseControls : true,
        this.touchControls = (options.touchControls) ? options.touchControls : true,
        this.gyroControls = (options.gyroControls) ? options.gyroControls : false,
        this.minHeight = (options.minHeight) ? options.minHeight : 200.00,
        this.minWidth = (options.minWidth) ? options.minWidth : 200.00,
        this.highlightColor = (options.highlightColor) ? options.highlightColor : 0x50505,
        this.midtoneColor = (options.midtoneColor) ? options.midtoneColor : 0x313131,
        this.lowlightColor = (options.lowlightColor) ? options.lowlightColor : 0x222222,
        this.baseColor = (options.baseColor) ? options.baseColor : 0x0,
        this.blurFactor = (options.blurFactor) ? options.blurFactor : 0.80,
        this.speed = (options.speed) ? options.speed : 1.50,
        this.zoom = (options.zoom) ? options.zoom : 0.70
    }
}
/*

        this.highlightColor = (options.highlightColor) ? options.highlightColor : 0xffffff,
        this.midtoneColor = (options.midtoneColor) ? options.midtoneColor : 0xf7f7f7,
        this.lowlightColor = (options.lowlightColor) ? options.lowlightColor : 0xededed,
        this.baseColor = (options.baseColor) ? options.baseColor : 0xe1e1e1,

 */