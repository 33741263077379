<template>
    <div class="product--slider">
        <ImageLoader class="background" :src="buildAssetPath('/assets/img/background-product.jpg')"></ImageLoader>
        <div class="content">
            <div class="slideshow" ref="slideshow">
                <div class="grid grid--slideshow">
                    <figure v-for="(item, index) in items" :key="index" :data-id="item.id" class="grid__item grid__item--slide">

                        <div class="item--info">
                            <item-detail-heading dense :item="item" :pricewhs="priceFormatter(item.ek)" :price="priceFormatter(item.price)"></item-detail-heading>
                        </div>

                        <div class="img-wrap">
                            <ImageLoader class="img" :src="buildAssetPath(item.image)"></ImageLoader>
                        </div>
                    </figure>
                    <div class="titles-wrap">
                        <div class="grid grid--titles">
                            <h3 v-for="(item, index) in items" :key="index" class="grid__item grid__item--title">{{ item.category }}</h3>
                        </div>
                    </div>
                    <div class="grid grid--interaction">
                        <div class="grid__item grid__item--cursor grid__item--left"></div>
                        <div class="grid__item grid__item--cursor grid__item--center"></div>
                        <div class="grid__item grid__item--cursor grid__item--right"></div>
                    </div>
                </div>
            </div>
        </div>

        <transition name="fade" mode="in-out">
            <div class="product--slider-detail" v-if="showProductsDetail">
                <ProductDetail :settings="settings" :content="productDetailContent" :state="productDetailState" ref="ProductDetail"></ProductDetail>
            </div>
        </transition>
    </div>
</template>

<script>
    import Slideshow from '../../assets/js/Slideshow.js';
    import { priceFormatterMixin } from "@/mixins/priceFormatterMixin";
    import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";

    export default {
        name: 'ProductSlider',
        mixins: [priceFormatterMixin, AssetPathBuilder],
        components: {
            ItemDetailHeading: () => import('@/components/ItemDetailHeading'),
        },
        props: { content:{type: Object}, state:{type: Object}, settings: {type: Object}},
        data(){
            return {
                current: null,
                slider: null,
                showProductsDetail: false,
                productDetailContent: {},
                productDetailState: {}
            }
        },
        computed: {
            items(){
                if(this.content.items)
                {
                    return this.content.items
                }

                if(this.content.groups && this.content.groups[0])
                {
                    return this.content.groups[0].data;
                }

                return []
                // return this.content.items || this.content.groups[0].data || [];
            },
        },
        methods: {
            updateCenterItem(id) {
                this.current = id;
                this.$emit('update-selected-item', parseInt(id, 10) );
            },
            updateAnimating(state) {
                this.$emit('update-animating-state', state);
            },
            navigateTo(direction, skipAnimation = false) {
                if(skipAnimation) {
                    this.slider.setSkipAnimation(true);
                }
                this.slider.navigate(direction);
            },
            openDetail(args){
                this.productDetailContent = {item: args.item};
                this.productDetailState = {sidebar: args.sidebar, sidebarClass: args.sidebarClass};
                this.showProductsDetail = true;
            },
            closeDetail(args){
                this.showProductsDetail = false;
            },
            zoomImg(args) {
                this.$refs.ProductDetail.zoomImg(args);
            },
            setFSonly (data) {
                this.$refs.ProductDetail.setFSonly(data);
            },
            scrollPercent(p){
                this.$refs.ProductDetail.scrollPercent(p);
            },
        },
        created() {
            // Initialize Slider
            this.$nextTick( () => {
                this.slider = new Slideshow(this);
            });
        }
    }
</script>

<style scoped lang="scss">

    .product--slider-detail{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
    }

    .product--slider {
        background: #C3C3C3;
        .background{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        .content{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            overflow: hidden;

            .grid {
                position: absolute;
                display: grid;
                height: 80%;
                width: 100%;
                left: 0;
                grid-template-columns: 35% 35% 35%;
                grid-column-gap: 0;
                grid-template-areas: 'griditem-left griditem-center griditem-right';
            }
            .grid--slideshow{
                top: 15%;
            }
            .grid--slideshow,
            .grid--interaction {
                left: -7.5%;
                width: 110%;
                pointer-events: none;
            }

            .titles-wrap {
                position: absolute;
                width: 100%;
                height: 100%;
                pointer-events: none;
                opacity: 0;
                .grid--titles {
                    align-items: center;
                    text-align: center;
                }
            }
            .grid__item {
                display: flex;
                position: relative;
                opacity: 0;
                grid-area: griditem-center;
            }
            .grid__item--slide {
                flex-direction: column;
                width: 100%;
                z-index: 100;
            }
            .grid__item--title {
                font-size: 8vw;
                margin: 0;
                font-family: ivymode, sans-serif;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.05);
                span {
                    display: inline-block;
                }
            }
            .grid__item--center,
            .grid__item--left,
            .grid__item--right {
                opacity: 1;
            }
            .grid__item--left {
                grid-area: griditem-left;
            }
            .grid__item--center {
                grid-area: griditem-center;
            }
            .grid__item--right {
                grid-area: griditem-right;
            }
            .grid__item--cursor {
                pointer-events: auto;
            }
            .content-open .grid__item--cursor {
                display: none;
            }


            .item--info{
                position: absolute;
                font-family: "Inter Light";
                color: white;
                line-height: 6vh;
                top: -10vh;
                .title{
                    font-size: 6vh;
                    font-weight: 400;
                    line-height: 6vh;
                    text-transform: capitalize;
                    span{
                        display: block;
                        //margin-left: 10px;
                        font-weight: 100;
                        opacity: 0.7;
                    }
                }
                .productId{
                    font-size: 1.4vh;
                    opacity: 0.7;
                    font-weight: 100;
                    letter-spacing: 0.4vh;
                }
                .price{
                    font-size: 3.2vh;
                    font-weight: 400;
                }
            }

            .img-wrap {
                width: 100%;
                position: relative;
                justify-content: center;
                align-self: center;
                display: flex;
                height: 100%;
                transform: translateZ(0);
                backface-visibility: hidden;
                .img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .img-wrap,
            .img--content,
            .price,
            .heading,
            .grid__item--title,
            .grid__item--title span,
            .revealer__inner,
            .content__item-header-title {
                will-change: transform;
            }
        }
    }


</style>