<template>
    <transition-group class="product--functions" name="fade-up" tag="div" appear>
        <div v-for="(el, index) in getFunctions" class="product--function" :key="'fn_'+index" :style="{transitionDelay: (index/10)+'s'}">
            <div class="function--image">
                <InlineSvg :src="buildAssetPath(el.image)" @error="svgError"></InlineSvg>
            </div>
            <div class="function--text">
                <div class="function--title">{{el.label}}</div>
                <div class="function--label">{{el.title}}</div>
            </div>
        </div>
    </transition-group>
</template>

<script>
    import InlineSvg from 'vue-inline-svg';
    import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";

    export default {
        name: 'ProductSidebarFunctions',
        components: {InlineSvg},
        mixins: [AssetPathBuilder],
        props: {
            item:{type: Object},
            settings: {type: Object}
        },
        computed: {
            getFunctions() {
                return this.item.details[2].data.slice(0,5);
            }
        },
        methods: {
            svgError(e){
                console.log(e);
            }
        }
    }
</script>

<style scoped lang="scss">
    .product--functions{
        padding: 2.5vw 2vw 2.5vw 2.5vw;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .product--function{
            display: flex;
            align-items: center;
            position: relative;
            width: 90%;
            height: 14vh;
            .function--image{
                width: 30%;
                height: 100%;
                margin-right: 1.5vw;
                display: flex;
                flex-direction: column;
                justify-content: center;
                svg {
                    max-width: 80%;
                    height: auto;
                }
                .filter--icon{
                    svg{
                        //fill: white !important;
                        max-height: 100%;
                        max-width: 100%;
                    }
                    svg path{
                        //fill: white !important;
                    }
                }
            }
            .function--text{
                display: flex;
                flex-flow: column;
                line-height: 3vh;
                width: 65%;

                .function--title{
                    font-family: "Inter Bold";
                    font-size: 2.2vh;
                    font-weight: 600;
                }
                .function--label{
                    font-size: 1.1vh;
                    font-weight: 200;
                    letter-spacing: 0.2vh;
                    text-transform: uppercase;
                    font-family: "Inter Light";
                }
            }
            /*&:not(:first-child){
                border-top: thin solid rgba(0,0,0,.12);
            }*/
        }
    }

    .product--function{
        transition: all 0.5s ease-in-out;
    }
    .fade-up-enter-active, .fade-up-leave-active {

    }
    .fade-up-enter, .fade-up-leave-to {
        opacity: 0;
        transform: translateY(50px);
    }
</style>