<template>
    <div class="kenburns-wrap" :key="src">
        <ImageLoader :src="src" class="kenburns"></ImageLoader>
    </div>
</template>

<script>
    export default {
        name: 'Kenburns',
        props: { src:{type: String} },
        computed: {

        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
    .kenburns-wrap{
        top: -10vh;
        bottom: -10vh;
        left: -10vw;
        right: -10vw;
        position: absolute;
        overflow: hidden;
        width: auto;
        height: auto;

        img.kenburns{
            position: absolute;
            object-fit: cover;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            animation: 150s ppb_kenburns linear infinite alternate;
        }
    }

    @keyframes ppb_kenburns{
        0% {
            transform: scale(1.3) translate(-10%, 10%);
        }
        25% {
            transform: scale(1) translate(0, 0);
        }
        50% {
            transform: scale(1.3) translate(10%, 10%);
        }
        75% {
            transform: scale(1) translate(0, 0);
        }
        100% {
            transform: scale(1.3) translate(-10%, 10%);
        }
    }
</style>