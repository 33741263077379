<template>
    <div class="product--collection" v-if="content.item" :class="{'less--info': lessInfo}">
        <div class="product--collection-container">
            <div v-for="(rangeItem, index) in collections" class="border-top product--collection-item d-flex"
                 :key="index">
                <div class="range--info d-flex" v-if="!lessInfo">
                    <div class="range--name">
                        <div class="range--name-relative">
                            {{ rangeItem.name }}
                        </div>
                    </div>
                    <div class="range--pairs-default">
                        {{ rangeItem.pairs_default }} Quantity
                    </div>
                    <!--
                    <div class="range--price-default">
                        WHS {{ rangeItem.ek ? priceFormatter(rangeItem.ek) : '0' }}
                    </div>
                    -->
                    <div class="range--price-default">
                        RRP {{ rangeItem.price ? priceFormatter(rangeItem.price) : '0' }}
                    </div>
                </div>
                <div class="flex-fill" v-if="!lessInfo">
                    <div class="range--dates">
                        <div class="range--date-item" v-for="rangeDate in rangeItem.dates">
                            <div class="range--date"
                                 :class="{'selected': rangeDate === rangeItem.deliveryDate}">
                                 {{ rangeDate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="product--collection-grid flex-fill ">
                    <div class="range--ranges">
                        <div class="range--item" v-for="(r, i) in rangeItem.sizes" :key="'index-' + i">
                            <div class="range--item-content" :class="{'selected': !lessInfo && r.usage > 0}">
                                <div class="in-usage" v-if="!lessInfo">
                                    {{ isEditable ? (rangeItem.count > 0 ? r.usage : r.range) : r.range }}
                                </div>
                                <div class="range-size">
                                    {{ i }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {priceFormatterMixin} from "@/mixins/priceFormatterMixin";
import {handleContentScroll} from "@/assets/js/mixins/handleContentScroll";

export default {
    name: 'ItemCollectionSingle',
    mixins: [priceFormatterMixin, handleContentScroll],
    data() {
        return {
            defaultRanges: {
                count: 9,
                start: 36
            },
            fsOnly: false
        }
    },
    props: {
        content: {type: Object},
        state: {type: Object}
    },
    computed: {
        firstItemRanges() {
            return this.content.item.ranges || this.defaultRanges;
        },
        range() {
            return {
                count: this.firstItemRanges.length,
                start: this.firstItemRanges[0],
            }
        },
        rangeCountVar() {
            return '--rangeCountVar: ' + this.range.count;
        },
        lessInfo() {
            return this.state ? this.state.lessInfo : false;
        },
        collections() {
            let collections = [];
            let itemCollections

            console.log(this.content.selections)

            if (this.content.selections) {
                collections.push(this.content.selections)
            } else {
                itemCollections = this.content.item.collection;
                collections = itemCollections.filter(collection => collection.isFreeRange);
            }

                console.log('collections', collections)
            // }
            // if (true === this.fsOnly) {

            // } else {
            //     collections = itemCollections.filter(collection => !collection.isFreeRange);
            // }
            return collections;
        },
        isEditable() {
            return this.state && this.state.editable;
        }
    },
    methods: {
        scrollPercent(p) {
            let container = document.querySelector('.product--collection-container');
            this.handleScrollPercent(container, p);
        },
        setFsOnly(args) {
            console.log('setFsOnly', args)
            this.fsOnly = args
        }
    }
}
</script>

<style lang="scss">
.range--ranges {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 0 1.6vh;

    .range--item {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: .5vh 0;

        .in-usage {
            font-size: 1.5vh;
            padding: .25vh;
            font-family: "Inter Bold";
        }
    }

    .range-size {
        font-size: .8vh;
    }
}
</style>

<style scoped lang="scss">
.flex-fill {
    flex: 1 0 100%;
}

.product--collection {
    font-family: "Inter Light";
    padding: 2vh;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1.6vh;
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
    &.less--info {
        .range-size {
            font-weight: bold;
        }
    }

    .product--collection-container {
        overflow: hidden;
        scroll-behavior: smooth;
    }

    &-grid {
        .range--ranges {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(10, auto);
            grid-column-gap: 1vh;
            grid-row-gap: 1vh;
            padding: 0;

            .range-size {
                font-size: 1.3vh;
            }
        }

        .range--item {
            position: relative;

            &-content {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background-color: #f0f0f0;
                transition: background-color .3s ease-in-out;

                &.selected {
                    background-color: #3c3c3c;
                    color: #ffffff;
                }
            }
        }

        .range--item:after {
            content: "";
            width: 0;
            padding-bottom: 100%;
        }
    }

    &-item {
        border-radius: 2vw;
        margin-bottom: .5vh;
        display: flex;
        flex-flow: row wrap;
        transition: all .3s ease-in-out;

        &.is-active {
            background-color: #000000;
            color: white;
        }
    }

    .range--header {
        padding-bottom: 1vh;

        .range--items {
            display: flex;

            .range--item {
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 1.6vh 0;
                font-weight: 600;
            }
        }
    }

    .border-top {
        .range--info {
            padding: 1.6vh;
            display: flex;
            align-items: center;
            flex-flow: row nowrap;
            flex: 1;

            .range--checked {
                .checked:not(.empty--check) {
                    width: 2.5vw;
                    margin-right: 2vw;
                    //height: 100%;
                    position: relative;

                    img {
                        object-fit: contain;
                        display: block;
                        position: absolute;
                        left: 0;
                        bottom: -0.8vh;
                    }
                }
            }

            .range--name {
                font-feature-settings: "tnum";
                min-width: 16vw;
                font-size: 2.5vh;
                font-family: "Inter Bold";

                span {
                    text-transform: uppercase;
                    font-size: 1.6vh;
                    font-weight: 400;
                    letter-spacing: 0.1vh;
                    padding-left: 3.2vh;
                    font-family: "Inter Extra Light";
                }
            }

            .range--price {
                font-size: 3vh;
                letter-spacing: 0.2vh;
                font-family: "Inter Bold";
                flex-grow: 1;
                text-align: right;
            }
        }

        .range--dates {
            display: flex;
            flex-wrap: wrap;

            padding: 1vh 0;
            font-size: 1vh;
            border-top: 1px solid #717573;

            .range--date-item {
                padding: .25vh;
                width: 20%;
            }

            .range--date {
                font-family: "Inter Bold";
                text-align: center;
                background-color: #f5f5f5;
                color: #9b9b9b;
                border-radius: .5vh;
                padding: 1vh 0;

                &.selected {
                    position: relative;
                    background-color: #3c3c3c;
                    color: #ffffff;
                }

                .date {
                    font-size: 0.75em;
                    margin-top: 0.5em;
                }
            }
        }
    }

    .range--pairs-default,
    .range--price-default {
        font-size: 1vh;
        font-family: "Inter Bold";
        white-space: nowrap;
    }

    .range--pairs-default {
        min-width: 15vw;
    }

    .range--price-default {
        min-width: 18vw;
    }

    .range--name-relative {
        position: relative;
        display: inline-flex;
    }

    .range--info-dates {
        flex: 1 0 100%;
    }

    .badge-container {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(110%, -20%);
    }

    .badge {

        font-size: 1vh;
        background-color: #4e5250;
        color: #ffffff;
        border-radius: 50%;
        width: 2vh;
        height: 2vh;
        display: flex;
        justify-content: center;
        align-items: center;

        &-success {
            background-color: #3cb171;
        }
    }

    .fade-up-enter-active, .fade-up-leave-active {
        transition: all .5s;
    }

    .fade-up-enter {
        transform: translateY(20px)
    }

    .fade-up-leave-to {
        transform: translateY(20px)
    }

    .fade-up-enter, .fade-up-leave-to {
        opacity: 0;
    }
}
</style>