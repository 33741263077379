<template>
    <div class="collections--list">

        <div class="groups" v-for="(group,x) in groups" :key="x">
            <div class="collections" v-for="(collectionItem, c) in group.data" :key="c">
                <div class="collection--item full-width" v-if="!showItemCollection(collectionItem) /*collectionItem.hasCollection === 0*/">
                    <div class="range--info d-flex">
                        <div class="range--info--col image">
                            <ImageLoader :src="buildAssetPath(collectionItem.image.replace('.png', '-thumb.png'))"
                                         class="range--info--image"></ImageLoader>
                        </div>
                        <div class="range--info--col">
                            <div class="range--item--name">{{ collectionItem.name }}</div>
                            <div class="range--label category">{{ collectionItem.category }}</div>
                        </div>
                        <div class="none-selected">Select Range</div>
                        <div class="range--image--col">
                            <bg-image-loader :key="collectionItem.id" :src="buildAssetPath(collectionItem.image)"></bg-image-loader>
                        </div>
                    </div>
                </div>
                <div v-for="(rangeItem, index) in collectionItem.collection" class="collection--item" :key="index"
                     v-if="(rangeItem.count > 0 || rangeItem.deliveryDate !== null)">
                    <div class="range--info d-flex">
                        <div class="range--info--col image">
                            <ImageLoader :src="buildAssetPath(collectionItem.image.replace('.png', '-thumb.png'))"
                                         class="range--info--image"></ImageLoader>
                        </div>
                        <div class="range--info--col">
                            <div class="range--item--name">{{ collectionItem.name }}</div>
                            <div class="range--label category">{{ collectionItem.category }}</div>
                        </div>
                        <div class="range--info--col">
                            <div class="range--value">{{ getNumberOfSizes(rangeItem)}}</div>
                            <div class="range--label">Number of Sizes</div>
                        </div>
<!--                        <div class="range&#45;&#45;info&#45;&#45;col">-->
<!--                            <div class="range&#45;&#45;value">{{ rangeItem.deliveryDate || '-' }}</div>-->
<!--                            <div class="range&#45;&#45;label">Lieferdatum</div>-->
<!--                        </div>-->
                        <div class="range--info--col">
                            <div class="range--value range--count">{{ getNumberOfQuantity(rangeItem) }}</div>
                            <div class="range--label">Total Quantity</div>
                        </div>
                        <div class="range--info--col range--price">
                            <div class="range--value">{{ priceFormatter(rangeItem.price) }}</div>
                            <div class="range--label">RRP</div>
                        </div>
                        <div class="range--info--col range--price">
                            <!--
                            <div class="range--value">{{ priceFormatter(rangeItem.ek) }}</div>
                            <div class="range--label">WHS</div>
                            -->
                        </div>
                    </div>
                    <div class="range--image--col">
                        <bg-image-loader :key="collectionItem.id" :src="buildAssetPath(collectionItem.image)"></bg-image-loader>
                    </div>
                </div>
            </div>
            <transition name="slide-y-transition" mode="out-in">
                <div v-if="group.data.length <= 0" class="empty--collection">No products in this group</div>
            </transition>
        </div>
    </div>
</template>

<script>
import {priceFormatterMixin} from "@/mixins/priceFormatterMixin";
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
import BgImageLoader from "../BgImageLoader";
import {handleContentScroll} from "@/assets/js/mixins/handleContentScroll";

export default {
    name: 'CollectionList',
    components: {BgImageLoader},
    mixins: [priceFormatterMixin, AssetPathBuilder, handleContentScroll],
    data() {
        return {
            defaultRanges: {
                count: 9,
                start: 36
            },
        }
    },
    props: {content: {type: Object}, state: {type: Object}, settings: {type: Object} },
    computed: {
        groups() {
            return this.content.groups || [this.content.group];
        },
        less() {
            return this.state.less;
        },
    },
    methods: {
        getNumberOfSizes(item) {
            let count = 0;
            for (const i in item.sizes) {
                if(item.sizes[i].usage > 0) count++;
            }
            return count
        },
        getNumberOfQuantity(item) {
            let count = 0;
            for (const i in item.sizes) {
                count += item.sizes[i].usage
            }
            return count
        },
        showItemCollection(item){
            for(let c in item.collection){
                if(item.collection[c].deliveryDate !== null){
                    return true;
                }
            }
            return item.hasCollection === 1;
        },
        scrollPercent(p) {
            let container = document.querySelector('.collections--list');
            this.handleScrollPercent(container,p);
        },
    }
}
</script>

<style scoped lang="scss">
    .collections--list {
        font-family: "Inter Light";
        background: white;
        padding: 2vh;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;

        scroll-behavior: smooth;

        .groups {
            &:not(:first-child) {
                margin-top: 6vh;
            }
        }

        .collections {
            &:last-child {
                border-bottom: 1px solid #e1e1e1;
            }

            .range--ranges {
                .range--item {
                    .in-usage {
                        font-size: 1.5vw;
                    }
                    .range-size {
                        font-size: .8vw;
                    }
                }
            }

            .collection--item {
                display: flex;
                padding: .5vh 0;
                height: 12vh;
                box-sizing: border-box;
                &.full-width {
                   .range--info {
                       width: 100%;
                   }
                }
                &:first-child {
                    border-top: 1px solid #e1e1e1;
                }

                &:not(:first-child) {
                    .range--info--image,
                    .range--item--name,
                    .range--item--id,
                    .range--label.category{
                        opacity: 0 !important;
                    }
                    .range--item--name{
                        height: 0;
                    }
                }

                .range--value {
                    font-size: 1vw;
                    font-family: "Inter Bold";
                }
                .range--label {
                    font-size: .8vw;
                }

                .range--item--name {
                    font-family: "Inter Bold";
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                    padding-right: 1vw;
                    width: 10vw;
                }

                .range--info {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    flex: 1;
                    .range--info--col {
                        display: flex;
                        flex: 1;
                        flex-direction: column;
                        height: 100%;
                        justify-content: center;
                        position: relative;
                        font-size: 1.6vh;
                        line-height: 1.5;
                        &.image {
                            max-width: 7vw;
                            padding: 1.6vh 0;
                            margin-right: 2vw;
                        }
                        .range--info--image {
                            max-height: 175%;
                            object-fit: contain;
                        }
                        .range--name {
                            font-family: "Inter Bold";
                            font-size: 2vw;
                        }
                        .range--item--name {
                            font-size: 2vh;
                        }

                        .range--item--id,
                        .range--date,
                        .range--price {
                            letter-spacing: 0.2vh;
                            font-weight: 200;
                            font-size: 1.4vh;
                            padding-top: 1vh;
                            span {
                                opacity: 0.6;
                            }
                        }

                        &.range--count,
                        &.range--price {
                            padding-left: 2vh;
                            padding-right: 2vh;
                        }

                        .range--number {
                            display: flex;
                            justify-content: center;

                            span {
                                background: #f5f5f5;
                                padding: 0.6vw 2vw;
                                font-weight: 600;
                                border-radius: 20vh;
                            }
                        }
                    }
                }
            }
        }
        .range--image--col {
            position: relative;
            width: 33.33%;
            height: 100%;
            margin-left: auto;
            .bg--image {
                background-size: 150% !important;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }

        .none-selected {
            font-size: 1.1vw;
            font-family: "Inter Bold";
            display: flex;
            align-items: center;
            justify-content: center;
            width: 45%;
            height: 100%;
            border-radius: 0.4vw;
            box-sizing: border-box;
            color: rgb(90, 90, 90);
        }


        .empty--collection{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 4vh;
            margin-top: 10vh;
        }

        .slide-y-transition-enter-active, .slide-y-transition-leave-active {
            transition: 1s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
        }
        .slide-y-transition-move {
            transition: transform 2s;
        }
        .slide-y-transition-enter, .slide-y-transition-leave-to {
            opacity: 0;
            transform: translateY(-5vh);
        }
    }
</style>