<template>
    <div class="color__item">
        <div class="color__item-dot" :style="{'background-color':  getRandomColor}"></div>
        <div class="image--sizer"></div>
        <ImageLoader class="product--image" :src="buildAssetPath(item.image)"></ImageLoader>
    </div>
</template>

<script>
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";

export default {
    name: 'ColorItem',
    props: {
        item: {
            type: Object,
            required: true
        },
        settings: {type: Object}
    },
    mixins: [AssetPathBuilder],
    data: () => ({

    }),
    computed: {
        getRandomColor() {
            return "#"+((1<<24)*Math.random()|0).toString(16);
        }
    }
}
</script>
<style lang="scss">
    .color__item-dot {
        width: 1vh;
        height: 1vh;
        border-radius: 1vh;
    }
</style>
<style scoped lang="scss">
.color__item {
    background-color: #fafafa;
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .image--sizer {
        padding-bottom: 480 / 370 * 100%;
        display: flex;
        flex: 1 0 0px;
    }
    img {
        max-width: 100%;
        position: absolute;
    }
}
    .color__item-dot {
        position: absolute;
        top: 1vh;
        left: 1vh;
    }
</style>