<template>
    <div class="whiteboard" :class="[{'dense': state.isDense},screenLayout]">
        <div class="whiteboard--group" v-for="(group,i) in content.groups" :key="group.pos">
            <headline v-if="!state || !state.collectionInfo" :class="{'dense': state.isDense}">
                {{group.name}}
                <template v-slot:label>
                    {{group.data.length}} <span v-if="!state.isDense">Products</span>
                </template>
            </headline>
            <transition-group name="slide-y-transition" mode="out-in" class="group--items" tag="div"
                   @before-leave="beforeLeave">
                <div class="whiteboard--item" :data-item-id="item.id" v-for="(item,j) in groupItems(group)"
                     :key="item.id + '_' + (item.collectionIndex || '0')">
                    <ImageLoader :src="buildAssetPath(item.image.replace('/shoes/', '/shoes/thumbs/'))"></ImageLoader>
                    <div class="item--info" v-if="state && state.collectionInfo && item.collectionIndex !== undefined">
                        <div class="collection_name">{{item.collection[item.collectionIndex].name}}</div>
                        <!--
                        <div class="collection_price">{{item.collection[item.collectionIndex].ek}}</div>
                        -->
                        <div class="collection_date">
                            {{item.collection[item.collectionIndex].deliveryDate || 'Kein Datum'}}
                        </div>
                    </div>
                    <div class="item--info" v-if="state && state.isDense">
                        <div class="item_name">{{item.name}}</div>
                        <div class="item_price">{{ priceFormatter(item.price) }} <!--<span>{{ priceFormatter(item.ek) }}</span>--></div>
                    </div>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<script>
import {priceFormatterMixin} from "@/mixins/priceFormatterMixin";
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
import {handleContentScroll} from "@/assets/js/mixins/handleContentScroll";
import ImageLoader from "@/components/ImageLoader";

export default {
    name: 'Whiteboard',
    mixins: [priceFormatterMixin, AssetPathBuilder, handleContentScroll],
    components: {ImageLoader},
    props: {content: {type: Object}, state: {type: Object}, settings: {type: Object} },
    computed: {
        screenLayout(){ return this.state.layout || 'landscape' },
    },
    data: () => ({
        hasCalculatedHeight: undefined
    }),
    methods: {
        getHighestGroup(className) {
                let max = 0;
                document.querySelectorAll(className).forEach(
                    function(el) {
                        // let style = el.currentStyle || window.getComputedStyle(el);
                        let h = el.getBoundingClientRect().height
                        if (h  > max) {
                            max = h;
                        }
                    }
                );
                return max;
        },
        groupItems(group) {
            if (!this.state || !this.state.collectionInfo) {
                return group.data;
            } else {
                let items = [];
                for (let item in group.data) {
                    if (group.data[item].hasCollection === 0) {
                        items.push(group.data[item]);
                    } else {
                        for (let collection in group.data[item].collection) {
                            if (group.data[item].collection[collection].count > 0) {
                                items.push(Object.assign({
                                    collectionIndex: collection,
                                }, group.data[item]));
                            }
                        }
                    }
                }
                return items;
            }
        },
        scrollToItemId(args) {
            document.querySelector(`.whiteboard--item[data-item-id="${args.itemId}"`).scrollIntoView();
        },
        scrollPercent(p) {
            let container = document.querySelector('.whiteboard');
            this.handleScrollPercent(container,p);
        },
        beforeLeave(el) {
            const {paddingLeft, paddingTop, width, height} = window.getComputedStyle(el)
            el.style.left = `${el.offsetLeft + parseFloat(paddingLeft, 10)}px`
            el.style.top = `${el.offsetTop + parseFloat(paddingTop, 10)}px`
            el.style.width = width
            el.style.height = height
        }
    }
}
</script>

<style scoped lang="scss">
    .whiteboard {
        background: white;

        display: flex;
        flex-direction: column;
        scroll-behavior: smooth;

        .whiteboard--group {
            display: flex;
            flex-direction: column;
            margin: 3.5vh 3.5vh 0;
        }

        .group--items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .whiteboard--item {
            width: calc(100% / 8);
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            padding: .5vh;

            img {
                max-width: 100%;
            }
        }

        .item--info {
            font-family: "Inter Light";
            display: flex;
            flex-direction: column;
            align-items: center;
            min-width: 0;
            width: 100%;

            .collection_name {
                background: #f5f5f5;
                font-weight: 100;
                padding: 0.5vh 0.8vw;
                border-radius: 10vh;
                font-size: 1.4vh;
            }

            .collection_price {
                font-weight: 600;
                font-family: "Futura PT Demi";
                letter-spacing: 0.1vh;
                font-size: 1.5vh;
                margin: 1.2vh 0 0.5vh;
            }

            .collection_date {
                text-transform: uppercase;
                font-weight: 100;
                font-size: 1vh;
                letter-spacing: 0.1vh;
            }

            .item_name{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
            }
        }

        &.dense {
            .whiteboard--item {
                width: calc(100% / 10);
            }
            .item--info {
                .item_name {
                    //font-size: 1.8vh;
                    font-size: 1.6vh;
                    font-family: "Inter Bold";
                }
                .item_price {
                    font-family: "Inter Bold";
                    margin-top: 0.5vh;
                    letter-spacing: 0vh;
                    font-size: 1.2vh;

                    span {
                        color: #737373;
                        font-size: 0.75em;
                        padding-left: 0.25em;
                    }
                }
            }
        }

        &.portrait{
            .whiteboard--item {
                width: calc(100% / 5);

                img{
                    max-height: 100%;
                    min-height: 100%;
                }
            }
            &.dense{
                width: calc(100% / 6);
            }
        }
    }
    .whiteboard--item {
        transition: all 1s;
    }

    .slide-y-transition-enter-active, .slide-y-transition-leave-active {
        transition: .5s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
    .slide-y-transition-leave-active {
        position: absolute;
    }
    .slide-y-transition-move {
        transition: transform 1s;
    }
    .slide-y-transition-enter {
        opacity: 0;
        transform: translateY(5vw);
    }
    .slide-y-transition-leave-to {
        transform: translateY(-5vw);
        opacity: 0;
    }

    .list-complete-enter, .list-complete-leave-to {
        opacity: 0;
        transform: translateY(3vh);
    }
    .list-complete-move {
        transition: transform 1s;
    }

    .list-complete-leave-active {
        //position: absolute;
    }
</style>