<template>
    <div class="grid__images" :class="content.type || 'default'">
        <div class="grid-top">
            <div class="grid-top-1 grid-image">
                <transition name="slide-x-transition" mode="out-in">
                    <div class="transition--container" :key="content.item.id ? content.item.id : Math.random().toString(36).substring(7)">
                        <bg-image-loader :src="buildAssetPath(content.item.images.bottom)"></bg-image-loader>
                    </div>
                </transition>
            </div>
            <div class="grid-top-2 grid-image">
                <transition name="fade-transition" mode="out-in">
                    <div class="transition--container" :key="content.item.id ? content.item.id : Math.random().toString(36).substring(7)">
                        <bg-image-loader :src="buildAssetPath(image2)"></bg-image-loader>
                    </div>
                </transition>
            </div>
            <div class="grid-top-3 grid-image">
                <transition name="fade-transition" mode="out-in">
                    <div class="transition--container" :key="content.item.id ? content.item.id : Math.random().toString(36).substring(7)">
                        <bg-image-loader :src="buildAssetPath(image3)"></bg-image-loader>
                    </div>
                </transition>
            </div>
        </div>

        <div class="grid-mid">
            <div class="grid-image">
                <transition name="fade-transition" mode="out-in">
                    <div class="transition--container" :key="content.item.id ? content.item.id : Math.random().toString(36).substring(7)">
                        <bg-image-loader :src="buildAssetPath(content.item.images.side)" background-size="250%"></bg-image-loader>
                    </div>
                </transition>
            </div>
        </div>

        <div class="grid-bottom">
            <div class="grid-bottom-1 grid-image">
                <transition name="fade-transition" mode="out-in">
                    <div class="transition--container" :key="content.item.id ? content.item.id : Math.random().toString(36).substring(7)">
                        <bg-image-loader :src="buildAssetPath(image5)"></bg-image-loader>
                    </div>
                </transition>
            </div>
            <div class="grid-bottom-2 grid-image">
                <transition name="slide-x-transition" mode="out-in">
                    <div class="transition--container" :key="content.item.id ? content.item.id : Math.random().toString(36).substring(7)">
                        <image-loader :src="buildAssetPath(image6)"></image-loader>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";

export default {
    name: 'GridImages',
    mixins: [AssetPathBuilder],
    components: {
        BgImageLoader: () => import('../BgImageLoader')
    },
    props: { content:{type: Object}, state:{type: Object}, settings: {type: Object}  },
    computed: {
        type() {
            return this.content.type
        },
        image2() {
            if(this.type == 'fashion') {
                return this.content.item.images.back
            } else {
                return this.content.item.topics.main
            }
        },
        image3() {
            if(this.type == 'fashion') {
                return this.content.item.images.bottom
            } else {
                return this.content.item.topics.right
            }
        },
        image5() {
            if(this.type == 'fashion') {
                return this.content.item.images.back
            } else {
                return this.content.item.topics.left
            }
        },
        image6() {
            if(this.type == 'fashion') {
                return this.content.item.images.side
            } else {
                return this.content.item.images.back
            }
        }
    }
}
</script>

<style lang="scss" scoped>

    $background-linear: linear-gradient(45deg, rgba(239,239,239,.5) 0%, rgba(206,204,204,1) 100%);

    .transition--container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .grid-top {
        padding: 1vh 1vh 0 1vh;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 1vh;
        grid-row-gap: 1vh;
        height: 45vh;
    }
    .grid-top-1 { grid-area: 1 / 1 / 5 / 2; }
    .grid-top-2 { grid-area: 1 / 2 / 4 / 3; }
    .grid-top-3 { grid-area: 4 / 2 / 5 / 3; }

    .grid-top-1 {
        background: rgba(239,239,239,1);
        background: $background-linear;
        .bg--image {
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    .grid-image {
        background: $background-linear;
    }

    .grid-mid {
        height: 25vh;
        padding: 1vh;
        .grid-image {
            width: 100%;
            height: 100%;

            .bg--image {
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
    }

    .grid-bottom {
        height: 30vh;
        padding: 0 1vh 1vh 1vh;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 1vh;
    }
    .grid-bottom-1 { grid-area: 1 / 1 / 2 / 3; }
    .grid-bottom-2 { grid-area: 1 / 3 / 2 / 6; }

    .grid-bottom-2 {
        background: $background-linear;
        &.grid-image {
            > div {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            img {
                position: absolute;
                width: auto;
                height: 90%;
            }
        }
    }

    .fashion {
        .transition--container {
            overflow: hidden;
        }
        .grid-bottom-1 {
            .bg--image {
                background-size: contain;
                transform: scale(3);
                background-position: center center;
            }
        }
        .grid-top-3 {
            .bg--image {
                transform: scale(1.5);
                background-position: center center;
            }
        }
    }

    .grid-image {
        position: relative;
    }

    img {
        max-width: 100%;
    }
</style>