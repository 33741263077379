<template>
    <div class="collections--grid">
        <div class="grid" id="grid"  v-bind:style="getGridStyle">
            <div class="grid--item" v-for="(item,x) in items" :style="{'grid-area': 'i-' + (x + 1)}"
                 :class="['i-' + (x + 1)]">
                <transition name="slide-x-transition" mode="out-in">
                    <div class="transition--container" :key="item.id ? item.id : Math.random().toString(36).substring(7)">
                        <bg-image-loader :src="buildAssetPath(item.image)" background-size="contain"></bg-image-loader>
                        <div class="color--name">{{ item.name }}</div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import {gridLayoutsMixin} from "@/mixins/gridLayoutsMixin";
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";

export default {
    name: 'ProductColors',
    mixins: [gridLayoutsMixin, AssetPathBuilder],
    components: {
        ColorItem: () => import('@/components/ColorItem')
    },
    props: {content: {type: Object}, state: {type: Object}, settings: {type: Object} },
    methods: {
        transitionDelay(i) {
            // let delay = [0.25,0,0.5];
            return i * .05 + 's';
        },
    },
}
</script>

<style scoped lang="scss">

    .grid {
        display: grid;
        width: 100vw;
        height: 100vh;
        grid-gap: 0.5em;
        padding: 1em;
        grid-template-rows: auto;
        grid-template-columns: auto;
    }
    .grid--item {
        background-color: rgb(245, 245, 245);
    }
    
    .bg--image {
        background-repeat: no-repeat;
        background-position: center;
    }

    .fade-down-enter-active, .fade-down-leave-active {
        transition: all 1s;
    }

    .fade-down-enter {
        transform: translateY(30px)
    }

    .fade-down-enter, .fade-leave-to {
        opacity: 0;
    }

    .transition--container {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .product--detail {
        background-color: #ffffff;
        padding: 1vh 1vh;

        .content {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    }

    .color--name {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 1vh;
        font-family: "Inter Bold";
        font-size: 1.2vh;
    }
</style>
