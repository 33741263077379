import {CONFIG} from './config';
const https = require('https');

export default class ShowroomScreen {
	constructor(id, data){
		this.id = id;
		this.data = data;
		this.onMessage = ()=>{};
	}

	log(msg){
		if(CONFIG.WS.LOG){
			console.log(msg);
		}
	}

	httpsPostRequest(url,path='/',postdata={}){
		postdata = JSON.stringify(postdata);

		return new Promise((resolve,reject)=>{
			let req = https.request({
				host: url,
				//port: '',
				path: path,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Content-Length': postdata.length
				}
			}, (res)=>{
				let str = '';
				res.on('data', function(chunk) { str += chunk; });
				res.on('end', function() {
					resolve(str);
				});
			}).on('error', (error)=>{
				reject(error);
			});
			req.write(postdata);
			req.end();
		});

	}

	getJWT(){
		let self = this;

		// todo uncomment this line to use jwt
		return Promise.resolve(false);
		//

		if(this.jwt) return Promise.resolve(this.jwt);

		return this.httpsPostRequest('showroom-manager.fire-commerce.com', '/de/app/json/showroomScreenToken',{
			screen_id: this.id,
		}).then(data=>{
			console.log(data);
			try{
				data = JSON.parse(data);
				self.jwt = data;
				return self.jwt;
			} catch (e){
				console.error(`invalid authServer response: ${e}`);
			}
		}).catch(e=>console.error(e));
	}

	connectWS(){
		let self = this;

		this.getJWT().then((jwt)=>{
			let url = CONFIG.WS.URL;
			if(jwt) url += '?token='+jwt;
			let ws = new WebSocket(url);
			ws.onopen = function(){
				self.log('websocket connected.');
				let data = JSON.stringify({
					"event": "register",
					"data": {
						"role": "showroom_screen",
						'screen_id': self.id,
						'screen_input': {}, //self.data,
					}
				});
				self.log(data);
				ws.send(data);
			};
			ws.onmessage = function(e){
				let data;
				try{
					data = JSON.parse(e.data);
					self.log(data);
				} catch(error){
					self.log(`Invalid JSON : ${e.data}`);
					return false;
				}

				if(data.event === 'screen_registered'){
					self.id = data.data.screen_id;
				} else if(data.event === 'update'){
					self.data = data.data;
				} else if(data.event === 'emit'){
					// todo
				}
				self.onMessage(data);
			};
			ws.onclose = function(e) {
				self.log(`ws closed. Exit-Code(${e.code})`);
				self.autoReconnect();
			};
			ws.onerror = function(e){
				self.log(`ws error: ${e}`);
			};
			this.ws = ws;
		}).catch(e=>console.error(e));
	}

	autoReconnect(delay=1000){
		let self = this;
		self.log(`attempting to reconnect WebSocket after ${delay/1000}s`);
		self.reconnectTimeout = setTimeout(function(){self.connectWS()},delay);
	}
}