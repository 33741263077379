export const priceFormatter  = (value, lang = 'de-de', currency = 'EUR') => {

    if(value && isNaN(value)) {
        value = value.replace(/[^0-9.,]/g, '');
    }

    let formatter = new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2
    });

    return formatter.format(value);
}