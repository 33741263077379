<template>
    <div class="collections--grid">
        <transition name="fade-transition" mode="out-in">
            <div class="grid" id="grid" :class="{'all--images-loaded': allImagesLoaded}" v-bind:style="getGridStyle" :key="Math.random().toString(36).substring(7)">
                <div class="grid--item" v-for="(item,x) in items" :style="{'grid-area': 'i-' + (x + 1)}" :class="['i-' + (x + 1)]" :key="x">
                    <bg-image-loader @image-loaded="loadedImages++" :src="buildAssetPath(getThumbName(item.image))" background-size="contain"></bg-image-loader>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ImageLoader from "../ImageLoader";
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
import { gridLayoutsMixin} from "@/mixins/gridLayoutsMixin";
import {handleContentScroll} from "@/assets/js/mixins/handleContentScroll";

export default {
    name: 'CollectionGrid',
    components: {ImageLoader},
    mixins: [gridLayoutsMixin, handleContentScroll, AssetPathBuilder],
    props: {content: {type: Object}, state: {type: Object}, settings: {type: Object} },
    data: () => ({
        loadedImages: 0,
        allImagesLoaded: false,
    }),
    watch: {
        loadedImages(value) {
            if(this.items.length == value) {
                this.allImagesLoaded = true
            }
        }
    },
    methods: {
        getThumbName(name) {
            if(this.items.length >  20) {
                return name.replace("/shoes/","/shoes/thumbs/")
            } else {
                return name;
            }
        },
        scrollPercent(p) {
            let container = document.querySelector('.collections--list');
            if(container)
            {
                this.handleScrollPercent(container,p);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .collections--grid {
        .grid {
            display: grid;
            width: 100vw;
            height: 100vh;
            grid-gap: 0.5em;
            padding: 1em;
            grid-template-rows: auto;
            grid-template-columns: auto;
        }
        .grid--item {
            background-color: rgb(245, 245, 245);
            position: relative;
            opacity: 0;
            transition: opacity .3s ease-in-out;
            .bg--image {
                background-repeat: no-repeat;
                background-position: center;
            }
            &.grid--zoom {
                overflow: hidden;
                .bg--image {
                    transform: scale(4);
                }
            }
        }
        .all--images-loaded {
            .grid--item {
                opacity: 1;
            }
        }
    }
</style>