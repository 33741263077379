<template>
    <transition v-if="screenID" :name="transitionName" :mode="transitionMode">
        <component :is="currentView" :state="viewState" :content="viewContent" :settings="settings" class="app" ref="currentView"></component>
    </transition>
    <transition v-else :name="transitionName" :mode="transitionMode">
        <div id="chooser-panal">
            <h1>Monitor auswählen</h1>

            <div class="button-list">
                <button @click="selectScreen(screen_left)">Links</button>
                <button @click="selectScreen(screen_main)">Mitte</button>
                <button @click="selectScreen(screen_right)">Rechts</button>
            </div>
        </div>
    </transition>
</template>
<script>
    import ShowroomScreen from './assets/js/ws_client';

    export default {
        name: "App",
        data() {
            return {
                view: 'Intro',
                transitionName: 'fade',
                transitionMode: 'out-in',
                screen: new ShowroomScreen(),

                screen_main: 1,
                screen_left: 2,
                screen_right: 3,
                is_remote: false,
                is_locale: false,
                settings: {
                    // language: 'de-ch',
                    language: 'en',
                    currency: 'EUR',
                    is_remote: false,
                    is_locale: false,
                }
            }
        },
        computed: {
            screenID(){
                return this.$route.query.screen_id || this.screen.id;
            },
            currentView(){
                return this.screen.data ? this.screen.data.view : this.view;
            },
            viewContent(){
                return this.screen.data ? this.screen.data.content : {};
            },
            viewState(){
                return this.screen.data ? this.screen.data.state : {};
            }
        },
        created () {
            console.log('remote');
            console.log(this.$route.query);
            
            // check if remote showroom
            this.settings.is_remote = parseInt(this.$route.query.remote) ? true : false;
            this.is_remote = this.settings.is_remote;

            // check if locale showroom
            this.settings.is_locale = parseInt(this.$route.query.locale) ? true : false;
            this.is_locale = this.settings.is_locale;

        },
        mounted() {
            console.log(this.is_remote);
            console.log(this.is_locale);

            let hostname = window.location.hostname;

            if(this.screenID){
                this.selectScreen(this.screenID);
            }

            let settings = window.localStorage.getItem('showroomSettings');
            if(settings) {
                settings = JSON.parse(settings);
                this.settings = settings;
            }

            this.screen.onMessage = (data)=>{

                if(data.event === 'emit' && data.data.emit){
                    if (this.$refs.currentView && data.data.emit.method === "setSettings"){
                        this.settings = {
                            ...this.settings,
                            ...data.data.emit.args
                        };

                        window.localStorage.setItem('showroomSettings', JSON.stringify(this.settings));
                    } else {
                        if (this.$refs.currentView && typeof this.$refs.currentView[data.data.emit.method] === "function"){
                            this.$refs.currentView[data.data.emit.method](data.data.emit.args);
                        }
                    }

                }

            };
        },
        methods: {
            selectScreen(id){
                this.screen.id = parseInt(id,10);
                this.screen.connectWS();
            },
        }
    }
</script>
<style lang="scss">
    @font-face {
        font-family: "Inter Light";
        src: url("~@/assets/fonts/Inter-Light.woff2") format("woff2");
    }
    @font-face {
        font-family: "Inter Bold";
        src: url("~@/assets/fonts/Inter-Bold.woff2") format("woff2");
    }
    @font-face {
        font-family: "Inter Extra Light";
        src: url("~@/assets/fonts/Inter-ExtraLight.woff2") format("woff2");
    }
    @font-face {
        font-family: "Inter Medium";
        src: url("~@/assets/fonts/Inter-Medium.woff2") format("woff2");
    }
    * {
        box-sizing: border-box;
    }
    html {
        font-family: "Inter Medium";
    }
    /**
    Slide Transition
     */
    .slide-x-transition-enter-active, .slide-x-transition-leave-active {
        transition: .5s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
    .slide-x-transition-move {
        transition: transform 1s;
    }
    .slide-x-transition-enter {
        opacity: 0;
        transform: translateX(10vw);
    }
    .slide-x-transition-leave-to {
        transform: translateX(-10vw);
        opacity: 0;
    }
    /**
    Fade Transition
     */
    .fade-transition-enter-active, .fade-transition-leave-active {
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
    .fade-transition-move {
        transition: transform 0.6s;
    }
    .fade-transition-enter, .fade-transition-leave-to {
        opacity: 0 !important;
    }

    /**
    Scroll X Transition
     */
    .scroll-x-transition-enter-active, .scroll-x-transition-leave-active {
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
    .scroll-x-transition-move {
        transition: transform 0.6s;
    }
    .scroll-x-transition-enter, .scroll-x-transition-leave-to {
        opacity: 0;
    }
    .scroll-x-transition-enter {
        transform: translateX(-15px);
    }
    .scroll-x-transition-leave-to {
        transform: translateX(15px);
    }


    /**
    Scroll Y Transition
     */
    .scroll-y-transition-enter-active, .scroll-y-transition-leave-active {
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
    .scroll-y-transition-move {
        transition: transform 0.6s;
    }
    .scroll-y-transition-enter, .scroll-y-transition-leave-to {
        opacity: 0;
    }
    .scroll-y-transition-enter {
        transform: translateY(-15px);
    }
    .scroll-y-transition-leave-to {
        transform: translateY(15px);
    }
</style>
<style scoped lang="scss">
    #chooser-panal {

        h1 {
            margin-bottom: 1em;
            color: white;
            text-align: center;
            font-size: 3em;
            font-weight: 300;
        }

        .button-list {
            display: flex;
            width: 75%;
            margin: 0 auto;
            justify-content: space-around;
        }

        button {
            display: inline-Block;
            color: black;
            width: 25%;
            line-height: 3;
            margin: 0 1%;
            padding: 0 0.5em;
            font-size: 4em;
            text-align: center;
            cursor: pointer;
            background: white;
            border: none;
            border-radius: 5px;
            outline: none;
            flex-grow: 1;
            flex-shrink: 1;

            &:hover {
                background-color: lightgrey;
            }
        }
    }


    .app{
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: #fff;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s
    }
    .fade-enter, .fade-leave-to {
        opacity: 0
    }
</style>