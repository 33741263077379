<template>
    <div class="footer--cart" :class="{closed: !active}">
        <div v-for="(stats, i) in groupStats" class="footer--cart--col" :class="[stats.class,'col-'+stats.cols]" :key="i">
            <span class="value">{{ stats.value }}</span>
            <span class="label">{{ stats.name }}</span>
        </div>
    </div>
</template>

<script>
    import countPairs from '../assets/js/countPairs.js';

    export default {
        name: 'FooterCart',
        props: { group: Object, active: Boolean },
        data(){
            return {}
        },
        computed: {
            groupStats() {
                let stats = [];

                // Group
                stats.push({name: 'Group', value: this.group.name, class: 'name', cols: 4});

                // Article
                stats.push({name: 'Products', value: this.group.data.length, cols: 2});

                // Pairs
                let pairs = 0;
                let ek_total = 0;
                let uvp_total = 0;
                this.group.data.forEach( v => {
                    let collections = v.collection;
                    collections.forEach(v => {
                        ek_total += parseFloat(v.ek);
                        uvp_total += parseFloat(v.price);
                        pairs += countPairs.getPairs(v);
                    });
                });
                stats.push({name: 'Total Quantity', value: pairs, cols: 2});

                // EK
                ek_total = ek_total.toFixed(2);
                stats.push({name: 'WHS', value: ek_total + ' €', cols: 2});

                // UVP
                uvp_total = uvp_total.toFixed(2);
                stats.push({name: 'RRP', value: uvp_total + ' €', cols: 2});

                return stats;
            },
        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">
    .footer--cart{
        background: #1e1e1e;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding: 4vh 4vw;
        justify-content: space-evenly;
        .footer--cart--col{
            display: flex;
            flex-direction: column;

            color: white;
            line-height: 3vh;

            .value{
                font-size: 2vh;
            }
            .label{
                font-size: 1.4vh;
                text-transform: uppercase;
                letter-spacing: 1px;
                opacity: 0.4;
                font-weight: 100;
            }
        }

        transition: all 0.5s;
        &.closed{
            transform: translateY(100%);
        }
    }
</style>