<template>
    <div class="topics--container">
        <transition name="fade" mode="out-in">
            <div class="topic--list" :class="{'all--images-loaded': allImagesLoaded}" :key="content.items[0].year || Math.random().toString(36).substring(7)">
                <div class="topic--item" v-for="(item, i) in content.items" :key="i" :style="itemStyle(item)" :data-item-id="item.id">
                    <div class="v-responsive__sizer"></div>
                    <ImageLoader @image-loaded="loadedImages++" :src="buildAssetPath(item.images.overview)" class="topic--background_image"></ImageLoader>
                    <div class="topic--background_overlay"></div>
                    <div class="topic--content">
                        <div class="topic--name">{{item.name}}</div>
                        <div class="topic--year">{{item.year}}</div>
                    </div>
                    <audio class="topic--audio" :data-item-id="item.id" preload>
                        <source :src="buildAssetPath(item.sound)">
                    </audio>
                </div>

                <div class="slideAnimationTarget"><div class="v-responsive__sizer"></div></div>
            </div>
        </transition>


        <div class="topic--details">
            <div class="details--image">
                <ImageLoader class="details--background" :src="buildAssetPath('/assets/img/background-product.jpg')"></ImageLoader>
                <div class="topic--detail--image" v-for="(item, j) in content.items" :key="j" :data-item-id="item.id">
                    <ImageLoader :src="buildAssetPath(item.image)"></ImageLoader>
                </div>
            </div>
            <div class="details--content">
                <div class="topic--detail--content" v-for="(item, j) in content.items" :key="j" :data-item-id="item.id">
                    <div class="content__image content__image--first">
                        <div class="v-responsive__sizer"></div>
                        <div class="content__image--reveal"></div>
                        <ImageLoader :src="buildAssetPath(item.images.main)" class="topic--detail_image"></ImageLoader>
                    </div>
                    <div class="content__image content__image--second">
                        <div class="v-responsive__sizer"></div>
                        <ImageLoader :src="buildAssetPath(item.images.left)" class="topic--detail_image"></ImageLoader>
                    </div>
                    <div class="content__image content__image--third">
                        <div class="v-responsive__sizer"></div>
                        <ImageLoader :src="buildAssetPath(item.images.right)" class="topic--detail_image"></ImageLoader>
                    </div>
                    <div class="content--title">
                        {{item.title}}<span>{{item.subtitle}}</span>
                        <div class="content--year">{{item.year}}</div>
                    </div>
                    <div class="content--description">{{item.description}}</div>
                </div>
            </div>
        </div>

        <transition name="fade" mode="in-out">
            <div class="topics--product-slider" v-if="showProductsSlider">
                <ProductSlider :content="productSliderContent" :state="{}" :settings="settings" ref="ProductSlider"></ProductSlider>
            </div>
        </transition>

        <transition name="fade" mode="in-out">
            <div class="topics--product-detail" v-if="showProductsDetail">
                <ProductDetail :content="productDetailContent" :state="productDetailState" :settings="settings" ref="ProductDetail"></ProductDetail>
            </div>
        </transition>

        <transition name="fade" mode="in-out">
            <div class="topics--mood" v-if="showMood" :key="Math.random().toString(36).substring(7)">
                <topics-mood :content="moodItems" :state="moodState" :settings="settings"></topics-mood>
            </div>
        </transition>
    </div>
</template>

<script>
    import ImageLoader from "@/components/ImageLoader";
    import TopicsTween from '../../assets/js/mixins/topics_tween.js';
    // import VantaConstructor from "@/utils/VantaConstructor";
    import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";

    export default {
        name: 'Topics',
        components: {
            ImageLoader,
            TopicsMood: () => import('@/components/TopicsMood'),
            // VVanta: () => import('vue-vanta'),
        },
        mixins: [TopicsTween, AssetPathBuilder],
        props: { content:{type: Object}, state:Object, settings: {type: Object} },
        computed: {

        },
        data(){
            return {
                showProductsSlider: false,
                showProductsDetail: false,
                productDetailContent: {},
                productSliderContent: {},
                productDetailState: {},
                showMood: false,
                moodItems: {},
                moodState: {},
                loadedImages: 0,
                allImagesLoaded: false
                // options: new VantaConstructor()
            }
        },
        mounted(){
            this.init_tween(document.querySelector('.topics--container'));
        },
        watch: {
            loadedImages(value) {
                if(this.content && this.content.items.length == value) {
                    this.allImagesLoaded = true
                }
            }
        },
        methods: {
            openTopic(args){
                this.animate_openDetail(args.current.id);
                this.playSound(args);
            },
            closeTopic(args){
                this.closeMood();
                this.animate_closeDetail();
                this.stopSound(args);
            },
            selectItem(args){
                this.animate_selectItem(args.current.id);
                this.playSound(args);
            },
            gridToSlider(args){
                this.animate_gridToSlider();
            },
            sliderToGrid(args){
                this.animate_sliderToGrid();
            },
            itemStyle(item){
                return {top: item.y+'%', left: item.x+'%', width: item.width+'%'}
            },
            playSound(args){
                this.stopSound(args);
                if(args.playSound){
                    let el = document.querySelector('.topic--audio[data-item-id="'+args.current.id+'"]');
                    el.volume = 1;
                    el.play();
                }
            },
            stopSound(args){
                document.querySelectorAll('.topic--audio').forEach((el)=>{
                    if(!el.paused) this.fadeSoundVolume(el);
                });
            },
            fadeSoundVolume(el,targetVolume = 0,delay=300){
                let steps = 10;

                let fadeInterval = setInterval(()=>{
                    el.volume = Math.max(el.volume -1/steps,0);
                    if(el.volume <= 0){
                        el.pause();
                        el.currentTime = 0;
                        clearInterval(fadeInterval);
                    }
                },delay/steps);
            },
            openProductSlider(args){
                if(this.showMood)
                {
                    this.closeMood();
                    this.showMood = false;
                }
                this.productSliderContent = args;
                this.showProductsSlider = true;
            },
            closeProductSlider(){
                this.showProductsSlider = false;
            },
            openDetail(args){
                this.productDetailContent = {item: args.item};
                this.productDetailState = {sidebar: args.sidebar, sidebarClass: args.sidebarClass};
                this.showProductsDetail = true;
            },
            closeDetail(args){
                this.showProductsDetail = false;
            },
            openMood(args) {
                this.moodItems = {
                    items: args.items
                }
                this.moodState = {
                    gridLayout: args.gridLayout ? args.gridLayout : ''
                }
                this.showMood = true
            },
            closeMood() {
                this.moodItems = {
                    items: []
                }
                this.showMood = false
            },
            navigateTo(direction){
                this.$refs.ProductSlider.navigateTo(direction);
            },
            init360(args) {
                this.$refs.ProductDetail.init360(args);
            },
            run360() {
                this.$refs.ProductDetail.run360();
            },
            rotate360(args) {
                this.$refs.ProductDetail.rotate360(args);
            },
            stop360() {
                this.$refs.ProductDetail.stop360();
            },
            zoomImg(args) {
                this.$refs.ProductDetail.zoomImg(args);
            }
        }
    }
</script>
<style scoped lang="scss">
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s
    }
    .fade-enter, .fade-leave-to {
        opacity: 0
    }


    .topics--container{
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #000;

        .topic--list{
            width: 100%;
            height: 100%;
            z-index: 0;

            &.all--images-loaded {
                .topic--item {
                    opacity: 1;
                }
            }

            .topic--item{
                position: absolute;
                overflow: hidden;
                will-change: transform;

                opacity: 0;
                transition: opacity .3s ease-in-out;

                .v-responsive__sizer{
                    padding-bottom: 133%;
                }

                .topic--background_image{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all .3s ease-in-out;
                }
                .topic--background_overlay{
                    position: absolute;
                    top: -1%;
                    left: -1%;
                    width: 102%;
                    height: 102%;
                    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%);
                }
                .topic--content{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 2vh 1vh 2.5vh 2.5vh;
                    box-sizing: border-box;
                    justify-content: flex-end;

                    font-family: "Inter Light";
                    color: white;
                    .topic--name{
                        font-size: 1.2vw;
                        font-weight: 400;
                        //letter-spacing: 0.1vh;
                        backface-visibility: hidden;
                        font-family: "Inter Medium";
                    }
                    .topic--year{
                        font-size: 0.5vw;
                        text-transform: uppercase;
                        letter-spacing: 0.4vh;
                        opacity: 0.6;
                        margin-top: 0.8vh;
                        backface-visibility: hidden;
                    }
                }
            }
            .slideAnimationTarget{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 28vw;
                .v-responsive__sizer{
                    padding-bottom: 133%;
                }
            }
        }

        .topics--mood {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9999;
            background-color: #fff;
        }

        .topic--details{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            display: flex;

            .details--image{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 50%;
                height: 0;
                will-change: transform;

                display: flex;
                justify-content: center;
                align-items: center;

                .details--background{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    will-change: transform;
                }
                .topic--detail--image{
                    position: absolute;
                    max-width: 100%;
                    z-index: 10;
                    opacity: 0;
                    margin-top: 50%;
                    will-change: transform;
                    img{
                        object-fit: contain;
                        width: 100%;
                        height: 100%;
                        will-change: transform;
                    }
                }
            }

            .details--content{
                position: absolute;
                top: 0;
                right: 0;
                width: 50%;
                height: 0;
                background: white;
                will-change: transform;

                box-sizing: border-box;

                .topic--detail--content{
                    display: flex;

                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    width: 100%;
                    height: 100%;
                    padding: 10vh;
                    box-sizing: border-box;

                    position: absolute;
                    top: 0;

                    .content__image {
                        position: absolute;
                        overflow: hidden;
                        z-index: 50;
                        opacity: 0;
                        will-change: transform;

                        top: 10vh;
                        &--first {
                            z-index: 100;
                            width: 12vw;
                        }
                        &--second {
                            width: 7vw;
                        }
                        &--third{
                            width: 9vw;
                        }

                        .v-responsive__sizer{
                            padding-bottom: 133%;
                        }

                        img{
                            object-fit: contain;
                            max-width: 100%;
                            max-height: 100%;
                            position: absolute;
                            top: 0;
                            will-change: transform;
                        }

                        .content__image--reveal {
                            position: absolute;
                            top: 0;
                            background: white;
                            width: 100%;
                            height: 100%;
                            z-index: 100;
                            transform: translate(0);
                            will-change: transform;
                        }
                    }

                    .content--title{
                        position: absolute;
                        top: 50%;
                        opacity: 0;
                        z-index: 100;
                        will-change: transform;
                        letter-spacing: 0.1vw;
                        font-family: "Inter Light";
                        font-size: 8vh;
                        text-align: center;
                        span{
                            font-weight: 100;
                            padding-left: 1vw;
                        }
                        .content--year{
                            letter-spacing: 0.4vw;
                            font-size: 2vh;
                            text-transform: uppercase;
                            padding-top: 1vh;
                        }
                    }
                    .content--description{
                        font-family: "Inter Light";
                        display: flex;
                        text-align: center;
                        justify-content: center;
                        position: absolute;
                        top: 70%;
                        opacity: 0;
                        z-index: 100;
                        width: 70%;
                        will-change: transform;
                        font-size: 2.4vh;
                        line-height: 1.8;
                    }
                }
            }
        }

        .topics--product-slider{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .topics--product-detail{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1000;
        }
    }
</style>