<template>
    <transition :name="transitionName">
        <img :src="src" alt="#" @load="loaded" :style="cssStyles">
    </transition>
</template>

<script>
    export default {
        name: 'ImageLoader',
        props: { src:{type: String}, transition:String, styles:Object },
        data(){
            return {
                isLoaded: false,
            }
        },
        computed: {
            cssStyles() { return Object.assign((this.styles || {}), {'opacity': this.isLoaded ? 1 : 0 }) },
            //cssStyles() { return this.styles || {} },
            transitionName(){ return this.transition || 'fade' },
        },
        methods: {
            loaded() {
                this.isLoaded = true;
                this.$emit('image-loaded')
            }
        }
    }
</script>

<style scoped lang="scss">
    img {
        transition: opacity .3s ease-in-out;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .fade-up-enter-active, .fade-up-leave-active {
        transition: all .5s ease-in-out;
    }
    .fade-up-enter, .fade-up-leave-to {
        opacity: 0;
        transform: rotate(20deg) translateY(200px);
        //margin-top: 200px;
    }
</style>