<template>
    <transition :name="transitionName">
        <div class="bg--image" :class="[imageClass, imageState]" :style="computedStyle" :data-width="imageWidth" :data-height="imageHeight" :data-state="imageState"></div>
    </transition>
</template>

<script>
export default {
    name: 'BgImageLoader',
    props: {
        src:{
            type: String
        },
        transition: {
            type: String
        },
        styles: {
            type: Object
        },
        imageClass: {
            type: String,
            required: false,
            default: ''
        },
        // loadingImage: {
        //     type: String,
        //     required: true
        // },
        // imageErrorCallback: {
        //     type: Function,
        //     required: false,
        //     default: function() {}
        // },
        // imageSuccessCallback: {
        //     type: Function,
        //     required: false,
        //     default: function() {}
        // },
        backgroundSize: {
            type: String,
            required: false,
            default: 'cover'
        }
    },
    data() {
        return {
            imageWidth: 0,
            imageHeight: 0,
            imageState: 'loading',
            asyncImage: new Image()
        }
    },
    computed: {
        transitionName(){ return this.transition || 'fade' },
        computedStyle() {
            // if (this.imageState === 'loading') {
            //     return 'background-image: url(' + this.loadingImage + ');'
            // }
            if (this.imageState === 'loaded') {
                return 'background-image: url(' + this.asyncImage.src + '); background-size: ' + this.backgroundSize
            }
            return '';
        }
    },
    methods: {
        fetchImage(url) {
            this.asyncImage.onload = this.imageOnLoad
            this.asyncImage.onerror = this.imageOnError
            this.imageState = 'loading'
            this.asyncImage.src = this.src
        },
        imageOnLoad(success) {
            this.imageState = 'loaded'
            this.imageWidth =  this.asyncImage.naturalWidth
            this.imageHeight = this.asyncImage.naturalHeight
            this.$emit('image-loaded')
            // this.imageSuccessCallback()
        },
        imageOnError(error) {
            console.log('src', this.src)
            console.log(error)
            this.imageState = 'error'
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.fetchImage()
        })
    }
}
</script>

<style scoped lang="scss">
    .bg--image {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }



    .fade-up-enter-active, .fade-up-leave-active {
        transition: all .5s ease-in-out;
    }
    .fade-up-enter, .fade-up-leave-to {
        opacity: 0;
        transform: rotate(20deg) translateY(200px);
        //margin-top: 200px;
    }
</style>