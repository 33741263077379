import {TweenMax, TimelineMax, Power2, Power3} from 'gsap';

export default class Slide {
	constructor(el, title) {
		this.DOM = {el: el};
		this.DOM.title = title;
		this.DOM.id = this.DOM.el.dataset.id;
		this.DOM.info = this.DOM.el.querySelector('.item--info');
		this.DOM.imgWrap = this.DOM.el.querySelector('.img-wrap');
		this.DOM.img = this.DOM.imgWrap.querySelector('.img');
	}

	// Move Slides
	move(direction, val, skipAnimation) {
		return new Promise((resolve) => {
			const tx = direction === 'left' ? '+=' + val * -1 : '+=' + val;
			let duration = 1.5;
			if(skipAnimation) {
				duration = 0;
			}

			new TimelineMax({onComplete: resolve})
				.to(this.DOM.imgWrap, duration, {
					x: tx,
					ease: Power2.easeInOut
				}, 0)
				.to(this.DOM.info, duration * 1.4, {
					x: tx,
					ease: Power2.easeInOut,
				}, 0)
				.to(this.DOM.title, duration * 1.2, {
					x: tx,
					ease: Power2.easeInOut
				}, 0);
		});
	}

	setCenter() {
		this.DOM.el.classList.add('grid__item--center');
		this.DOM.title.classList.add('grid__item--center');
		TweenMax.set([this.DOM.el, this.DOM.title], {opacity: 1});
		TweenMax.set([this.DOM.info], {opacity: 1});
		TweenMax.set(this.DOM.imgWrap, {scale: '1.4', filter: 'blur(0px)'});
	}

	setRight() {
		this.DOM.el.classList.add('grid__item--right');
		this.DOM.title.classList.add('grid__item--right');
		TweenMax.set([this.DOM.el, this.DOM.title], {opacity: 1});
		TweenMax.set([this.DOM.info], {opacity: 0});
		TweenMax.set(this.DOM.imgWrap, {scale: '.8', filter: 'blur(4px)'});
	}

	setLeft() {
		this.DOM.el.classList.add('grid__item--left');
		this.DOM.title.classList.add('grid__item--left');
		TweenMax.set([this.DOM.el, this.DOM.title], {opacity: 1});
		TweenMax.set([this.DOM.info], {opacity: 0});
		TweenMax.set(this.DOM.imgWrap, {scale: '.8', filter: 'blur(4px)'});
	}

	reset() {
		TweenMax.set([this.DOM.el, this.DOM.imgWrap, this.DOM.info, this.DOM.title], {transform: 'none'});
		TweenMax.set([this.DOM.el, this.DOM.title], {opacity: 0});
		this.DOM.title.classList = 'grid__item grid__item--title';
		this.DOM.el.classList = 'grid__item grid__item--slide';
	}
}