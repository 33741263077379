<template>
    <div class="product--collection">

        <div class="range--header">
            <div class="range--items" :style="rangeCountVar">
                <div class="range--item" v-for="(n, i) in range.count" :key="i">
                    {{ n + (range.start - 1) }}
                </div>
            </div>
        </div>


        <div v-for="(rangeItem, index) in collections" class="border-top" :key="index" v-if="!(lessInfo && rangeItem.isFreeRange)">

            <div class="range--info d-flex">
                <div v-if="!lessInfo" class="range--checked">
                    <div class="checked empty--check" v-if="rangeItem.count === 0"></div>
                    <div class="checked" v-else><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="100" cy="100" r="100"/><path d="M60 102.6L87.4 130 140 77.4l-7.6-7.4-45.1 45-19.9-19.9-7.4 7.5zm0 0" fill="#fff"/></svg></div>
                </div>
                <div class="range--name">
                    {{ rangeItem.name }}<span v-if="!lessInfo">{{ lessInfo ? calculateDefaultPairs(rangeItem) : rangeItem.pairs }} Products</span>
                </div>

                <div class="range--price" v-if="!lessInfo">
                    {{ less ? calculateDefaultPrice(rangeItem, item) : rangeItem.ek }} {{$defaultCurrency}}
                </div>
            </div>
            <transition-group tag="div" name="scroll-y-transition" mode="out-in" class="range--ranges" :style="rangeCountVar">
                <div class="range--item" v-for="(r, i) in rangeItem.range" :key="'index-' + i">
                    <div style="display: none;">{{ rangeItem.usage[i] = rangeItem.count > 0 ? rangeItem.range[i] * rangeItem.count : rangeItem.range[i] }}</div>
                    <div v-if="!less" class="in-stock" :class="{bubble: rangeItem.range[i] > 0, reached: rangeItem.usage[i] === rangeItem.stock[i], danger: rangeItem.usage[i] > rangeItem.stock[i]}">
                        {{ rangeItem.stock[i] }}
                    </div>
                    <div class="in-usage">
                        {{ rangeItem.range[i] }}
                    </div>
                    <div v-if="!less" class="in-range" :class="{bubble: rangeItem.range[i] > 0}">
                        {{ rangeItem.range[i] }}
                    </div>
                </div>
            </transition-group>

            <div class="range--dates" v-if="!lessInfo">
                <div class="range--date" v-for="rangeDate in rangeItem.dates" :class="{'selected': rangeDate === rangeItem.deliveryDate}">{{rangeDate}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import countPairs from '../assets/js/countPairs.js';

    export default {
        name: 'ProductSidebarCollection',
        data() {
            return {
                defaultRanges: {
                    count: 9,
                    start: 36
                },
            }
        },
        props: {
            item: { type: Object },
            less: { type: Boolean },
            state: { type: Object },
            fsOnly: {type: Boolean }
        },
        computed: {
            firstItemRanges(){
                return this.item.ranges || this.defaultRanges;
            },
            range(){
                return {
                    count: this.firstItemRanges.length,
                    start: this.firstItemRanges[0],
                }
            },
            rangeCountVar(){
                return '--rangeCountVar: ' + this.range.count;
            },
            lessInfo(){
                return this.state ? this.state.lessInfo : false;
            },
            collections() {
                let collections = [];

                if(true === this.fsOnly) {
                    collections = this.item.collection.filter(collection => collection.name == 'FS');
                } else {
                    collections = this.item.collection.filter(collection => collection.name != 'FS');
                }

                return collections;
            }
        },
        methods: {
            calculateDefaultPairs(item) {
                return countPairs.getPairsDefault(item);
            },
            calculateDefaultPrice(item, parent) {
                let pairs = this.calculateDefaultPairs(item);

                return (pairs * parent.ek).toFixed(2);
            },
        }
    }
</script>

<style scoped lang="scss">
    .product--collection{
        font-family: "Inter Light";
        padding: 5vh;
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 1.6vh;
        box-sizing: border-box;
        overflow: hidden;
        height: 100%;

        scroll-behavior: smooth;

        .range--header{
            padding-bottom: 2.4vh;
            .range--items{
                display: flex;
                .range--item{
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 1.6vh 0;
                    font-weight: 600;
                }
            }
        }

        .border-top{
            border-top: 1px solid #e1e1e1;
            .range--info{
                padding: 3.2vh 1.6vh 1.6vh;
                display: flex;
                align-items: baseline;
                .range--checked {
                    .checked:not(.empty--check){
                        width: 2.5vw;
                        margin-right: 2vw;
                        //height: 100%;
                        position: relative;
                        img{
                            object-fit: contain;
                            display: block;
                            position: absolute;
                            left: 0;
                            bottom: -0.8vh;
                        }
                    }
                }
                .range--name {
                    font-feature-settings: "tnum";
                    min-width: 20vw;
                    font-weight: 600;
                    font-size: 4.5vh;
                    span{
                        text-transform: uppercase;
                        font-size: 1.6vh;
                        font-weight: 400;
                        letter-spacing: 0.1vh;
                        padding-left: 3.2vh;
                    }
                }
                .range--price{
                    font-size: 3vh;
                    letter-spacing: 0.2vh;
                    font-weight: 600;
                    flex-grow: 1;
                    text-align: right;
                }
            }
            .range--ranges {
                display: flex;
                flex-direction: row;
                padding-bottom: 2.4vh;
                flex: 1;
                .range--item{
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: #f5f5f5;
                    border-radius: 0.4vw;
                    border-right: 0.2vw solid #fff;
                    border-left: 0.2vw solid #fff;
                    padding: 1.6vh 0;
                    .in-usage{
                        font-weight: 600;
                        font-size: 2.2vh;
                        padding: 1.6vh 0;
                    }
                    .in-stock, .in-range{
                        display: none;
                    }
                }
            }
            .range--dates{
                display: flex;
                flex-wrap: wrap;
                padding: 0 1.6vh;
                letter-spacing: 0.1vh;
                font-size: 1.2vh;
                .range--date{
                    margin-right: 2.4vh;
                    margin-bottom: 2.4vh;
                    &.selected{
                        position: relative;
                        &:after{
                            content: "";
                            position: absolute;
                            height: 0.2vh;
                            width: 100%;
                            bottom: -0.8vh;
                            left: 0;
                            background: black;
                        }
                    }
                }
            }
        }
    }
</style>