import GridConstructor from "../utils/GridConstructor";

export const gridLayoutsMixin = {
    props: {
        settings: {type: Object}
    },
    data: () => ({
        portrait: new GridConstructor().getPortrait(),
        landscape: new GridConstructor().getLandscape()
    }),
    computed: {
        getGridStyle() {
            let contentItems = this.items;
            let grid;

            if(this.state && this.state.gridLayout) {
                grid = this.state.gridLayout;
            } else {
                grid = 'portrait';
            }
            if(contentItems && contentItems.length > 0)
            {
                return {'grid-template-areas': this[grid][contentItems.length]};
            }
            return {};
        },
        getGridRandomZoom() {
            let min = 0;
            let max = this.items.length;
            let spacing = 2;
            let count = 0;

            if (this.rangeInterval(max, 4, 5)) {
                count = 2;
            }
            else if (this.rangeInterval(max, 6, 10)) {
                count = 3;
            }

            else if (this.rangeInterval(max, 11, 20)) {
                count = 5;
            }

            else if (this.rangeInterval(max, 21, 30)) {
                count = 8;
            }

            else if (this.rangeInterval(max, 31, 40)) {
                count = 10;
            }

            else if (this.rangeInterval(max, 41, 50)) {
                count = 14;
            }
            console.log(min,max,count,spacing)

            return this.randomSpaceInterval(min,max,count, spacing);
        },
        items() {
            let items = this.content.items;

            if(items && items.length > 50) {
                items = items.splice(0, 50);
            }
            return items;
        }
    },
    methods: {
        rangeInterval(x, min, max) {
            return x >= min && x <= max;
        },
        randomSpaceInterval(min, max, count, spacing) {
            let available = max-min - spacing * (count-1);
            if (available<0) return false;

            let arr = [];
            for (let i = 0; i<count; i++) {
                let temp	 = Math.round( Math.random()*available / (count-1)*2 );
                arr[i]		 = (i==0)? min+temp : arr[i-1] + temp + spacing;
                available 	-= temp;
            }
            return arr;
        },
    }
}