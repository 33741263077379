<template>
    <div>
        <ImageLoader
            class="fullscreen"
            v-if="isDefault"
            :src="buildAssetPath(content.image)" :key="content.image"></ImageLoader>
        <Kenburns
            v-else
            :src="buildAssetPath(content.image)" :key="content.image"></Kenburns>
    </div>

</template>

<script>
    import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
    import Kenburns from "../Kenburns";
    export default {
        name: 'FullscreenImage',
        components: {Kenburns},
        mixins: [AssetPathBuilder],
        props: { state:{type: Object}, content:{type: Object}, settings: {type: Object}  },
        computed: {
            isDefault() {
                return this.state && this.state.default;
            }
        }
    }
</script>

<style scoped lang="scss">
    img.fullscreen{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
</style>