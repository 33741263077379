<template>
    <div class="filter--container" :class="{'dark--bg': state && state.isOverview}">
        <template v-if="state && state.isOverview">
            <v-vanta class="vanta-bg" effect="fog" :options=options></v-vanta>
        </template>
        <div class="overview">
            <div class="overview--header">
                <headline white>
                    <transition name="scroll-y-transition" mode="out-in">
                        <div class="header-title" :key="headline">
                            {{ headline }}
                        </div>
                    </transition>

                    <template v-slot:label>
                        <transition name="scroll-y-transition" mode="out-in">
                            <div class="header-sublabel" :key="headline">
                                <div>{{ countProducts }} <span>Products</span></div>
                            </div>
                        </transition>
                    </template>

                </headline>
            </div>
            <transition name="scroll-y-transition" mode="out-in">

                <div class="overview--body" v-if="state && state.isOverview" :key="'overview-' + headline">
                    <div class="menu--item" v-for="(item, i) in content.menu" :key="i"
                         :class="{selected: item.selected}">
                        <span>{{item.name}}</span>
                        <product-filter-active-filters v-if="item.filters.length > 0" :filters="item.filters"></product-filter-active-filters>
                    </div>
                </div>
                <div v-else class="selected--filter_content">
                    <transition tag="div" name="scroll-y-transition" mode="out-in">
                        <div class="height-100 scrollable" v-if="getFilterOptions" :key="headline">

                            <template v-if="categoryView">
                                <div class="list--category"
                                     v-for="item in selectedFilterOptions.filters"
                                     :key="item.id"
                                >
                                    <div class="list--category-icon">
                                        <InlineSvg :src="buildAssetPath(item.image_sh)"></InlineSvg>
                                    </div>
                                    <div class="list--category-content">
                                        <div class="list--category-main">
                                            {{ item.name }}
                                            <transition name="scroll-y-transition" mode="out-in">
                                                <svg
                                                    v-if="selectedFilter.includes(item.id)"
                                                    :key="item.id"
                                                    class="checked-icon"
                                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                                                    <path fill="#fff" d="M0 106.4L68.5 175 200 43.6 181.1 25 68.5 137.5 18.6 87.6 0 106.4z"/>
                                                </svg>
                                            </transition>
                                        </div>
                                        <div
                                            v-if="item.filters.length"
                                            class="list--category-sub">
                                        <span
                                            v-for="item in item.filters"
                                            :key="item.id"
                                            :class="{'is--active': selectedFilter.includes(item.id)}"
                                        >
                                            {{ item.name }}
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <transition-group tag="div" name="scroll-y-transition" class="filter--list height-100"
                                                  mode="out-in" v-bind:style="getGridStyle">
                                    <div v-if="show" class="filter--list_item" :class="{'list--view': listView}"
                                         v-for="item in selectedFilterOptions.filters"
                                         :key="item.id"

                                    >
                                        <div class="filter--list_item-content"
                                             :class="[{price: selectedFilterOptions.name === 'Preis'}, {'bg-light': selectedFilterOptions.type === 'icon'}]"
                                             :style="setBackgroundColor(item)"
                                        >
                                            <template v-if="selectedFilterOptions.type === 'image'">
                                                <bg-image-loader :src="buildAssetPath(item.image_sh)"></bg-image-loader>
                                                <div class="background--overlay"></div>
                                            </template>

                                            <div class="filter--icon" v-if="selectedFilterOptions.type === 'icon'">
                                                <InlineSvg :src="buildAssetPath(item.image_sh)"></InlineSvg>
                                            </div>

                                            <transition name="scroll-y-transition" mode="out-in">
                                                <div v-if="selectedFilter.includes(item.id)" class="filter--checked" :class="getBgBrightness(item)">
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                                                        <path d="M0 106.4L68.5 175 200 43.6 181.1 25 68.5 137.5 18.6 87.6 0 106.4z"/>
                                                    </svg>
                                                </div>
                                            </transition>

                                            <div class="filter--name"
                                                 :class="[getBgBrightness(item),{price: selectedFilterOptions.name === 'Preis'}]">
                                                {{ item.name }}
                                            </div>
                                            <div class="filter--name2">
                                                {{item.name2 || ''}}
                                            </div>
                                        </div>

                                    </div>
                                </transition-group>
                            </template>
                        </div>
                    </transition>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
// color, image, icon, size, title

import ImageLoader from "../ImageLoader";
import {brightness} from "../../assets/js/utils/color.js";
import InlineSvg from 'vue-inline-svg';
import BgImageLoader from "../BgImageLoader";
import VantaConstructor from '@/utils/VantaConstructor.js';
import ProductFilterActiveFilters from "../ProductFilterActiveFilters";
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
import { handleContentScroll } from "@/assets/js/mixins/handleContentScroll";

export default {
    name: 'ProductFilter',
    components: {
        ProductFilterActiveFilters,
        BgImageLoader, ImageLoader, InlineSvg,
        VVanta: () => import('vue-vanta'),
    },
    props: {state: {type: Object}, content: {type: Object}, settings: {type: Object} },
    mixins: [AssetPathBuilder, handleContentScroll],
    data: () => ({
        show: true,
        isAnimating: false,
        selectedFilterOptions: {},
        options: new VantaConstructor(),
        grid: {
            style: {
                gridTemplateAreas: '',
                gridTemplateColumns: '',
                gridTemplateRows: ''
            }
        }
    }),

    computed: {
        getFilterOptions() {
            return this.content.selectedFilterOptions || false;
        },
        selectedFilter() {
            return this.content.selectedFilter;
        },
        categoryView() {
            return this.selectedFilterOptions.view === 'category';
        },
        listView() {
            return this.selectedFilterOptions.view === 'list';
        },
        countProducts() {
            return this.content.countProducts || 0;
        },
        headline() {
            return this.getFilterOptions ? this.getFilterOptions.name : 'Filter'
        },
        getGridStyle() {
            if (!this.selectedFilterOptions.filters) {
                return {}
            }

            let total = this.selectedFilterOptions.filters.length
            let grid = {
                gridTemplateAreas: '',
                gridTemplateColumns: '',
                gridTemplateRows: '',
                rows: 1
            }
            if (total == 1) {
                grid.gridTemplateAreas = '"i-1"';
            }
            if (total == 2) {
                grid.gridTemplateAreas = '"i-1" "i-2"';
            }
            else if (total > 2 && total <= 4) {
                grid.gridTemplateAreas = '"i-1 i-2" "i-3 i-4"';
                grid.gridTemplateColumns = '1fr 1fr';
                grid.gridTemplateRows = '1fr 1fr ';
                grid.rows = 2;
            }
            else if (total > 4 && total <= 6) {
                grid.gridTemplateAreas = '"i-1 i-2" "i-3 i-4" "i-5 i-6"';
                grid.gridTemplateColumns = '1fr 1fr';
                grid.gridTemplateRows = '1fr 1fr 1fr';
                grid.rows = 3;
            }
            else if (total > 6 && total <= 9) {
                grid.gridTemplateAreas = '"i-1 i-2 i-3" "i-4 i-5 i-6" "i-7 i-8 i-9"';
                grid.gridTemplateColumns = '1fr 1fr 1fr';
                grid.gridTemplateRows = '1fr 1fr 1fr';
                grid.rows = 3;
            }
            else if (total > 9 && total <= 12) {
                grid.gridTemplateAreas = '"i-1 i-2 i-3" " i-4 i-5 i-6" "i-7 i-8 i-9" "i-10 i-11 i-12"';
                grid.gridTemplateColumns = '1fr 1fr 1fr';
                grid.gridTemplateRows = '1fr 1fr 1fr 1fr';
                grid.rows = 4;
            }
            else if (total > 12 && total <= 16) {
                grid.gridTemplateAreas = '"i-1 i-2 i-3 i-4" "i-5 i-6 i-7 i-8" "i-9 i-10 i-11 i-12" "i-13 i-14 i-15 i-16"';
                grid.gridTemplateColumns = '1fr 1fr 1fr 1fr';
                grid.gridTemplateRows = '1fr 1fr 1fr 1fr';
                grid.rows = 4;
            }
            else if (total > 16 && total <= 20) {
                grid.gridTemplateAreas = '"i-1 i-2 i-3 i-4" "i-5 i-6 i-7 i-8" "i-9 i-10 i-11 i-12" "i-13 i-14 i-15 i-16" "i-17 i-18 i-19 i-20"';
                grid.gridTemplateColumns = '1fr 1fr 1fr 1fr';
                grid.gridTemplateRows = '1fr 1fr 1fr 1fr 1fr';
                grid.rows = 5;
            }
            else if (total > 20 && total <= 25) {
                grid.gridTemplateAreas = '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-13 i-14 i-15" "i-16 i-17 i-18 i-19 i-20" "i-21 i-22 i-23 i-24 i-25"';
                grid.gridTemplateColumns = '1fr 1fr 1fr 1fr 1fr';
                grid.gridTemplateRows = '1fr 1fr 1fr 1fr 1fr';
                grid.rows = 5;
            }
            else if (total > 25) {
                grid.gridTemplateAreas = '"i-1 i-2 i-3 i-4 i-5" "i-6 i-7 i-8 i-9 i-10" "i-11 i-12 i-13 i-14 i-15" "i-16 i-17 i-18 i-19 i-20" "i-21 i-22 i-23 i-24 i-25" "i-26 i-27 i-28 i-29 i-30"';
                grid.gridTemplateColumns = '1fr 1fr 1fr 1fr 1fr';
                grid.gridTemplateRows = '1fr 1fr 1fr 1fr 1fr 1fr';
                grid.rows = 6;
            }
            return {
                gridTemplateAreas: grid.gridTemplateAreas,
                gridTemplateColumns: grid.gridTemplateColumns,
                gridTemplateRows: grid.gridTemplateRows,
                '--rows': grid.rows
            }
        },
    },
    watch: {
        'getFilterOptions.name': {
            deep: true,
            immediate: true,
            handler(newValue, oldValue) {
                if (newValue != oldValue) {
                    this.isAnimating = true
                    this.animateFilters()
                }

            }
        },
        'getFilterOptions.filters': {
            deep: true,
            immediate: true,
            handler(newValue, oldValue) {
                if (newValue != oldValue && !this.isAnimating) {
                    this.selectedFilterOptions = this.getFilterOptions
                }
            }
        }
    },
    methods: {
        animateFilters() {
            this.show = false
            setTimeout(() => {
                this.selectedFilterOptions = {}
                this.show = true
                this.selectedFilterOptions = this.getFilterOptions
            }, 350)
        },
        getFilterName(id) {
            let names = this.content.filterNames || [];
            let name = '';
            for (let i in names) {
                if (names[i].id === id) {
                    name = names[i].name;
                    break;
                }
            }
            return name;
        },
        setBackgroundColor(item) {
            if (item.hex) {
                return {backgroundColor: item.hex};
            }
        },
        getBgBrightness(item) {
            if (item.hex) {
                let treshold = 0.7;
                return brightness(item.hex) > treshold ? 'bg-light' : 'bg-dark';
            } else return '';
        },
        scrollPercent(p){
            let container = document.querySelector('.selected--filter_content');
            this.handleScrollPercent(container,p);
        },
    }
}
</script>

<style lang="scss">
    .filter--icon {
        svg {
            fill: white !important;
            max-height: 80%;
            max-width: 80%;
        }

        svg path {
            fill: white !important;
        }
    }
</style>

<style scoped lang="scss">

    $overview-padding-x: 5vw;

    $menu-item-border: 1px solid rgba(255, 255, 255, .1);

    $filter-active-base-size: 40vw;

    .filter--container {
        display: flex;
        background-color: #000;
        transition: background-color .5s ease-in-out;
    }

    .overview {
        padding: 1vh;
        height: 100vh;
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;

        &--header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            //padding-left: $overview-padding-x;
            //padding-right: $overview-padding-x;
            flex-shrink: 0;
            min-height: 10vh;

            padding: 2.5vh 5vw;   // same paddings as overview--header in whiteboard, -1 vh top from container

            .header--element{
                margin-bottom: 0;
                padding-left: 0;
            }
        }

        .header-count {
            color: rgba(var(--header-color-count), .6);
        }

        &--body {
            height: 75%;
            display: flex;
            flex-direction: column;

            .menu--item {
                color: #ffffff;
                flex: 1;
                display: flex;
                align-items: center;
                border-top: $menu-item-border;
                border-bottom: $menu-item-border;
                padding-left: $overview-padding-x;
                padding-right: $overview-padding-x;
                font-size: 2.8vh;
                justify-content: space-between;
                position: relative;
            }
        }

        .selected--filter_content {
            height: 100%;
            flex: 1;
            overflow: hidden;
            scroll-behavior: smooth;
        }

        .filter--checked {
            position: absolute;
            top: 50%;
            left: 50%;
            width: calc(#{$filter-active-base-size} / var(--rows));
            height: calc(#{$filter-active-base-size} / var(--rows));
            margin-left: calc(#{$filter-active-base-size} / var(--rows) / 2 * -1);
            margin-top: calc(#{$filter-active-base-size} / var(--rows) / 2 * -1);
            background-color: rgba(0,0,0,.5);
            backdrop-filter: blur(calc(20px / var(--rows)));
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            svg {
                width: 30%;
                fill: #fff;
            }
        }

        .filter--name {
            position: absolute;
            bottom: 1vh;
            width: 100%;
            padding: 1vh;
            text-align: center;
            color: inherit;
            font-family: "Inter Bold";
            font-size: calc(10vw / var(--rows));
            &.bg-light {
                color: #000;
            }
        }

        .filter--icon {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 50%;
                height: 50%;
            }
        }

        .list--category {
            color: #ffffff;
            flex: 1;
            display: flex;
            flex-direction: row;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            position: relative;
            padding: 2vh 5vw;
            &-icon {
                width: 15vw;
                flex-shrink: 0;
                text-align: left;
                display: flex;
                align-items: center;
                svg {
                    width: 70%;
                    height: auto;
                }
                path {
                    fill: #fff !important;
                }
            }
            &-content {
                display: flex;
                flex-direction: column;
            }
            &-main {
                font-size: 2.4vh;
                display: flex;
                align-items: center;
                .checked-icon {
                    width: 1vh;
                    margin-left: 1vh;
                }
            }
            &-sub {
                margin-top: 1.5vh;
                font-size: 1vh;
                display: flex;
                flex-flow: row wrap;
                gap: 1vh;

                span {
                    padding: 1vh;
                    background-color: rgba(255,255,255,.2);
                    border-radius: .5vh;
                    white-space: nowrap;
                    opacity: .5;
                    transition: opacity .3s ease-in-out, background-color .3s ease-in-out;
                    &.is--active {
                        opacity: 1;
                        background-color: rgba(255,255,255,.3);
                    }
                }
            }
        }

        .filter--list_item {
            background-color: #2d2d2d;


            &.list--view {
               .filter--list_item-content {
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   flex-direction: column;
               }
                .filter--name {
                    color: #fff;
                    font-family: "Inter Bold";
                    position: relative;
                    font-size: calc(18vw / var(--rows));
                    padding-bottom: 1vh;
                }
                .filter--name2 {
                    color: #fff;
                    opacity: .5;
                    font-size: calc(8vw / var(--rows));
                }
            }
        }
        .filter--list_item-content {
            height: 100%;
            position: relative;
            color: #fff;
            &.bg-light {
                color: #fff;
            }
        }

        .filter--list {
            grid-gap: 1rem;
            display: grid;
        }

        .background--overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, 0) 70%);
        }
    }

    .height-100 {
        height: 100%;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s
    }

    .fade-enter, .fade-leave-to {
        opacity: 0
    }


    .scroll-y-transition-enter-active, .scroll-y-transition-leave-active {
        transition: 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

    .scroll-y-transition-move {
        transition: transform 0.6s;
    }

    .scroll-y-transition-enter, .scroll-y-transition-leave-to {
        opacity: 0;
    }

    .scroll-y-transition-enter {
        transform: translateY(15px);
    }

    .scroll-y-transition-leave-to {
        transform: translateY(-15px);
    }

    .filter-list-enter-active, .filter-list-leave-active {
        transition: all .3s ease-in-out;
    }

    .filter-list-transition-enter {
        opacity: 0;
        transform: translateY(15px);
    }

    .filter-list-transition-leave-to {
        opacity: 0;
        transform: translateY(-15px);
    }
</style>
<style lang="scss">
.list--category {
    &-icon {
        path {
            fill: #ffffff !important;
        }
    }
}
</style>