<template>
    <div class="item--description" :class="{dense: dense}">
        <div class="title">{{item.name}}<span>{{item.category}}</span></div>
        <div class="productId">{{item.sku}}</div>
        <div class="price">{{ price }}</div>
        <div class="price-ek-label">RRP</div>

        <!--
            <div class="price">{{ price }}<span>{{ pricewhs }}</span></div>
            <div class="price-ek-label">RRP / WHS</div>
        -->
    </div>
</template>

<script>
export default {
    name: 'ItemDetailHeading',
    props: {
        item: {
            type: Object
        },
        price: {
            type: String
        },
        pricewhs: {
            type: String
        },
        dense: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>

    $title-color: #3c3c3c;

    .item--description {
        &:not(.dense) {
            top: 10vh;
            left: 10vh;
        }
        position: absolute;
        font-family: "Inter Light";
        color: white;
        line-height: 6vh;

        &.dense {
            .title {
                font-size: 6vh;
                line-height: 6vh;
                span {
                    font-size: 4vh;
                }
            }
        }

        .title {
            font-size: 8vh;
            line-height: 8vh;
            font-family: "Inter Bold";
            color: $title-color;
            margin-left: -5px;
            white-space: nowrap;

            span {
                display: block;
                font-size: 6vh;
                opacity: 0.5;
                font-family: "Inter Extra Light";
            }
        }

        .productId {
            margin-top: 2vh;
            font-size: 1.6vh;
            color: $title-color;
            white-space: nowrap;
            line-height: 1;
        }

        .price {
            font-size: 1.6vh;
            color: $title-color;
            line-height: 1.5;
            font-family: "Inter Bold";
            margin-top: 1vh;

            span {
                font-size: 0.75em;
                color: #737373;
                padding-left: 0.5em;
            }
        }

        .price-ek-label {
            font-family: "Inter Medium";
            color: $title-color;
            margin-top: 0.5vh;
            font-size: 1.2vh;
            line-height: 1;
            letter-spacing: 0.2vh;
        }
    }
</style>