<template>
    <div class="checkout--overview">

        <div class="checkout--content" v-if="content.groups.length">
            <div class="checkout--item" v-for="(item, index) in content.groups" :key="index">
                <div class="checkout--item-icon">
                    <div class="item-icon">
                        <img src="@/assets/img/showroom-checkout.svg">
                    </div>
                </div>
                <div class="checkout--item-content">
                    <div class="item-name">{{ item.name }}</div>
                    <div class="item-stats">
                        <div class="articles item-stat">{{ item.articles }} <span>Products</span></div>
                        <div class="pairs item-stat">{{ item.pairs }} <span>Total Quantity</span></div>
                        <div class="uvp item-stat">{{ item.uvp }}<span>RRP</span></div>
                        <!--
                        <div class="ek item-stat">{{ item.ek }} <span>WHS</span></div>
                        -->
                    </div>
                </div>
                <div class="checkout--item-action">
                    <transition name="slide-left-in" mode="out-in">
                        <span v-show="stats && stats.selected && stats.selected.includes(item.id)">
                            <div class="checked"></div>
                        </span>
                    </transition>
                </div>
            </div>
        </div>
        <div class="checkout--overview empty" v-else>
            <div class="checkout--empty">You don't have groups to display</div>
        </div>

        <div class="checkout--footer-container">
            <transition name="slide-up" mode="out-in">
                <div v-if="content.groups.length && stats && stats.articles > 0" class="checkout--footer">
                    <div class="checkout--row headline">
                        Total
                    </div>
                    <div class="checkout--row">
                        <div class="value">{{stats.articles}}</div>
                        <div class="label">Products</div>
                    </div>
                    <div class="checkout--row">
                        <div class="value">{{stats.pairs}}</div>
                        <div class="label">Total Quantity</div>
                    </div>

                    <!--
                    <div class="checkout--row">
                        <div class="value">{{stats.ek}}</div>
                        <div class="label">WHS</div>
                    </div>
                    -->

                    <div class="checkout--row">
                        <div class="value">{{stats.uvp}}</div>
                        <div class="label">RRP</div>
                    </div>
                </div>
            </transition>
        </div>

        <div class="checkout--message" :class="{'is--active': successMessage}">
            <transition name="slide-y-transition" mode="out-in">
                <div v-if="messageKey" style="transition-delay: .5s" :key="messageKey" class="checkout--message-text">{{ successMessage }}</div>
            </transition>
        </div>
    </div>
</template>

<script>

import {handleContentScroll} from "@/assets/js/mixins/handleContentScroll";

export default {
    name: 'CheckoutOverview',
    data: () => ({
       stats: {},
        successMessage: undefined,
        messageKey: undefined
    }),
    props: {content: {type: Object}, state: {type: Object}},
    mixins: [handleContentScroll],
    computed: {},
    methods: {
        updateStats(args) {
            this.stats = args.stats;
        },
        showMessage(args) {
            let timeOutDuration = args.duration ? args.duration : 5000
            this.successMessage = args.message ? args.message : ''
            this.messageKey = Math.random().toString(36).substring(7)

            setTimeout( () => {
                this.successMessage = ''
                this.messageKey = ''
            }, timeOutDuration);
        },
        scrollPercent(p){
            let container = document.querySelector('.checkout--content');
            this.handleScrollPercent(container,p);
        },
    }
}
</script>

<style scoped lang="scss">

    $container-max-width: 90vw;

    .checkout--overview {
        display: flex;
        flex-direction: column;
        height: 100vh;
        &.empty {
            justify-content: center;
            align-items: center;
            font-size: 4vh;
        }
    }
    .checkout--content {
        flex: 1;
        padding: 2.5vh;
        overflow: hidden;

        padding-bottom: 17.5vh;
    }
    .checkout--item {
        display: flex;
        flex-direction: row;
        padding: 3.5vh 1vw;
        margin: 0 auto;
        max-width: $container-max-width;
        align-items: center;
        &:not(:last-child){
            border-bottom: 1px solid rgba(220,220,220,0.5);
        }
        &-icon {
            margin-right: 3vw;
            .item-icon {
                background-color: rgba(220,220,220, .3);
                border-radius: 50%;
                width: 12vh;
                height: 12vh;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 50%;
                    height: 50%;
                }
            }
        }
        &-content {
            flex: 1;
        }
        .item-name {
            font-family: "Inter Bold";
            font-size: 2.2vw;
            padding-bottom: 2vh;
        }
        .item-stats {
            display: flex;
            flex-direction: row;
        }

        .item-stat {
            font-size: 1.4vw;
            font-family: "Inter Light";
            display: flex;
            align-items: baseline;
            span {
                font-size: .6vw;
                text-transform: uppercase;
                font-family: "Inter Bold";
                padding-left: .5vw;
            }
            min-width: 20%;

            &.ek, &.uvp {
                padding-left: 1vh;
                padding-right: 1vh;
                span {
                    white-space: nowrap;
                }
            }
        }
    }
    .checkout--item-action {
        width: 10vw;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .checked {
            width: 7vh;
            height: 7vh;
            background-image: url('../../assets/img/ui-checked.svg');
        }
    }
    .checkout--footer-container {
        //min-height: 15vh;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .checkout--footer {
        margin: 0 auto;
        max-width: $container-max-width;
        background-color: #000;
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 15vh;
        .checkout--row {
            flex: 1;
            font-family: "Inter Light";
            .value {
                font-size: 1.2vw;
                padding-bottom: 1vh;
            }
            .label {
                text-transform: uppercase;
                font-size: .6vw;
            }
        }
        .headline {
            font-size: 1.5vw;
            padding-left: 4vw;
            font-family: "Inter Bold";
        }
    }

    .checkout--message {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.1);
        backdrop-filter: blur(10px);
        opacity: 0;
        transition: opacity .3s ease-in-out;
        &.is--active {
            opacity: 1;
        }
        &-text {
            //background-color: rgba(0,0,0,.8);
            padding: 6vh;
            font-family: "Inter Bold";
            font-size: 3vw;
            max-width: 60vw;
            color: #000;
        }
    }

    .slide-left-in-enter-active, .slide-left-in-leave-active {
        transition: all .5s ease-in-out;
    }
    .slide-left-in-enter {
        transform: translateX(100%)
    }
    .slide-left-in-leave-to {
        transform: translateX(100%)
    }
    .slide-left-in-enter, .slide-left-in-leave-to {
        opacity: 0;
    }

    .slide-up-enter-active, .slide-up-leave-active {
        transition: all .5s ease-in-out;
    }
    .slide-up-enter {
        transform: translateY(100%)
    }
    .slide-up-leave-to {
        transform: translateY(100%)
    }
    .slide-up-enter, .slide-up-leave-to {
        opacity: 0;
    }

    .slide-y-transition-enter-active, .slide-y-transition-leave-active {
        transition: 1s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
    }
    .slide-y-transition-move {
        transition: transform 2s;
    }
    .slide-y-transition-enter, .slide-y-transition-leave-to {
        opacity: 0;
        transform: translateY(-5vh);
    }
</style>