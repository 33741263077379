<template>
    <div class="intro" :class="theme">
        <transition name="scroll-x-transition" mode="out-in">
            <Kenburns v-if="content.image && '' !== content.image" :src="buildAssetPath(content.image)" :key="content.image ? content.image : Math.random().toString(36).substring(7)"></Kenburns>
        </transition>
        <template v-if="!content.image">
            <v-vanta class="vanta-bg" effect="fog" :options=options :key="'theme'+ theme"></v-vanta>
        </template>
        <div class="overlay"></div>
        <div v-if="content.introtext || content.title || content.subtitle || content.subtitle_2" class="content" :class="state && state.layout || 'default' " :key="content.title+'_'+ (state && state.layout ? state.layout : '')">
            <div class="title-container">
                <transition name="title-fade" appear>
                    <div v-if="content.introtext && content.introtext !== ''" class="intro-text">{{content.introtext}}
                    </div>
                </transition>
                <transition name="title-fade" appear>
                    <h1 style="transition-delay: .5s;">{{content.title}}</h1>
                </transition>
                <transition name="subtitle-fade" appear>
                    <h2 style="transition-delay: 1s;">
                        {{content.subtitle}}<span>{{content.subtitle_2 || ''}}</span></h2>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import VantaConstructor from '@/utils/VantaConstructor.js';
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";

export default {
    name: 'Intro',
    props: {state: {type: Object}, content: {type: Object}, settings: {type: Object} },
    mixins: [AssetPathBuilder],
    components: {
        VVanta: () => import('vue-vanta'),
    },
    computed: {
        theme() {
            return this.state && this.state.theme ? 'theme--' + this.state.theme : '';
        },
        options() {
            let options = {}
            if(this.state && this.state.theme && this.state.theme == 'light') {
                 options = {
                    highlightColor: 0xffffff,
                    midtoneColor: 0xf7f7f7,
                    lowlightColor: 0xededed,
                    baseColor: 0xe1e1e1
                }
            }
            return new VantaConstructor(options)
        }
    }
}
</script>
<style lang="scss">
    .vanta-container {
        canvas {
            width: 100vw !important;
            height: 100vh !important;
        }
    }
</style>
<style scoped lang="scss">

    $box-container-width: 90vw;
    $box-center-h1: 4vh;
    $box-center-h2: 3vh;

    $box-intro-white-h1: 3.25vh;
    $box-intro-white-h2: 2.75vh;

    $box-intro-white-text: 1.5vh;

    .intro {
        width: 100%;
        height: 100%;
        display: flex;

        .overlay {
            //background: rgba(10, 10, 10, 0.15);
            background: linear-gradient(180deg, rgba(0,0,0,0) 65%, rgba(0,0,0,0.15) 100%);  // tbd
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .content {
            position: relative;
            display: flex;

            .title-container {
                display: flex;
                flex-direction: column;
            }

            &.centered {
                justify-content: center;
                align-items: center;
                flex: 1;
                .title-container {
                    align-items: center;
                }

                h1 {
                    font-family: 'Inter Bold';
                    color: white;
                    margin-top: 0;
                    margin-bottom: 1vh;
                    font-size: $box-center-h1;
                    line-height: 5vh;
                    text-align: center;
                }

                h2 {
                    font-family: 'Inter Extra Light';
                    color: white;
                    margin: 0;
                    font-size: $box-center-h2;
                    letter-spacing: 1px;
                }

                h2 {
                    color: rgba(255, 255, 255, 0.6);
                    padding: 0 2em;
                    text-align: center;
                }
            }

            &.default {
                justify-content: center;
                padding: 24vw;

                h1, h2 {
                    margin: 0;
                    color: white;
                    font-family: 'Futura PT';
                    font-weight: 400;
                    font-size: 18vw;
                }

                h2 {
                    color: rgba(255, 255, 255, 0.6);
                    border-bottom: 6px solid;
                    align-self: flex-end;
                }
            }

            &.intro-white {
                padding: 5vh;
                width: $box-container-width;
                min-width: $box-container-width;
                align-self: flex-start;

                &.bottom {
                    align-self: flex-end;
                }

                .title-container {
                    background-color: rgba(255, 255, 255, .1);
                    padding: 3.5vh 3vh;
                    backdrop-filter: blur(40px);
                    width: 100%;
                }

                .intro-text {
                    font-family: 'Inter Light';
                    color: white;
                    opacity: .6;
                    font-size: $box-intro-white-text;
                    padding-bottom: 2vh;
                }

                h1 {
                    font-family: 'Inter Bold';
                    color: white;
                    margin-top: 0;
                    margin-bottom: 1vh;
                    font-size: $box-intro-white-h1;
                    line-height: 4vh;
                }

                h2 {
                    font-family: 'Inter Extra Light';
                    color: white;
                    margin: 0;
                    font-size: $box-intro-white-h2;
                    letter-spacing: 1px;
                }
            }

            &.textbox {
                align-items: baseline;

                .title-container {
                    background: black;
                    padding: 5vh 5vw;
                    position: absolute;
                    right: 0;
                    bottom: 18vh;
                }

                h1 {
                    margin: 0;
                    color: white;
                    font-family: 'Futura PT';
                    font-weight: 400;
                    font-size: 5vh;
                    line-height: 0.97;
                }

                h2 {
                    margin: 0;
                    font-family: 'Futura PT';
                    font-weight: 400;
                    color: rgba(255, 255, 255, 0.35);
                    font-size: 3vh;
                    margin-top: 1rem;
                }
            }
        }
    }


    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .title-fade-enter-active, .title-fade-leave-active {
        transition: all 0.75s ease-in-out;
    }

    .title-fade-enter, .title-fade-leave-to {
        opacity: 0;
        transform: translateY(50px);
    }

    .subtitle-fade-enter-active, .subtitle-fade-leave-active {
        transition: all 0.75s ease-in-out;
    }

    .subtitle-fade-enter, .subtitle-fade-leave-to {
        opacity: 0;
        transform: translateY(50px);
    }
</style>