<template>
    <div class="mood--background">
        <ImageLoader
            class="fullscreen"
            :src="buildAssetPath(content.image)" :key="content.image"></ImageLoader>
        <div class="mood--background-picture">
            <transition name="scroll-y-transition" mode="out-in">
                <ImageLoader
                    class="picture"
                    :src="buildAssetPath(content.picture)"
                    :key="content.picture"
                ></ImageLoader>
            </transition>
        </div>
    </div>

</template>

<script>
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
export default {
    name: 'MoodBackgroundPicture',
    mixins: [AssetPathBuilder],
    props: { state:{type: Object}, content:{type: Object}, settings: {type: Object}  },
    computed: {
        isDefault() {
            return this.state && this.state.default;
        }
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">
.mood--background {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    &-picture {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}
img.picture {
    max-width: 100%;
    height: auto;
}
img.fullscreen{
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>