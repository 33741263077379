<template>
    <div class="image__container">
        <div class="background__image">
            <transition-group tag="div" name="fade-transition" mode="out-in">
                <img alt="" v-if="show" class="image_old" :src="buildAssetPath(oldImage)" :key="'bg-' +content.image">
                <img alt="" v-if="!show" class="image_new" :src="buildAssetPath(newImage)" :key="'bg-' +content.image">
            </transition-group>
        </div>
<!--            <div class="background__image" :style="{ 'background-image': 'url(' + content.image + ')' }"></div>-->
        <div class="blurred"></div>
        <transition tag="div" name="scroll-x-transition" mode="out-in">
            <img class="main_image" alt="" :src="buildAssetPath(content.image)" :key="content.image">
        </transition>
        <div class="preload_images" key="preloaded" v-show="preloadedImages">
            <img v-for="image in images" alt="" :src="buildAssetPath(image)">
        </div>
    </div>
</template>
<script>
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
export default {
    name: 'MediaImage',
    props: {state: {type: Object}, content: {type: Object}, settings: {type: Object} },
    mixins: [AssetPathBuilder],
    data: () => ({
        preloadedImages: false,
        show: false,
        images: [],
        oldImage: undefined,
        newImage: undefined
    }),
    computed: {},
    methods: {
        setImages(args) {
            this.images = args
        }
    },
    watch: {
        'content.image': {
            immediate: true,
            handler(newValue, oldValue) {
                this.show = true
                this.newImage = newValue
                this.oldImage = oldValue
                this.show = false
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .image__container {
        display: flex;
        justify-content: center;
        align-items: center;
        .blurred {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            backdrop-filter: blur(10px);
        }

        img.main_image {
            height: 80vh;
            position: relative;
            z-index: 5;
            object-fit: contain;
        }

        .background__image {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            img {
                display: block;
                width: 100vw;
                height: 100vh;
                object-fit: cover;
                position: absolute;
                &.image_old {
                    z-index: 1;
                }
                &.image_new {
                    z-index: 2;
                }
            }
        }
    }

    .slide-x-transition-enter-active, .slide-x-transition-leave-active {
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

    .slide-x-transition-move {
        transition: transform 0.6s;
    }

    .slide-x-transition-enter, .slide-x-transition-leave-to {
        opacity: 0;
        transform: translateX(-15px);
    }

    .scroll-x-transition-enter-active, .scroll-x-transition-leave-active {
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }
    .scroll-x-transition-move {
        transition: transform 0.6s;
    }
    .scroll-x-transition-enter, .scroll-x-transition-leave-to {
        opacity: 0;
    }
    .scroll-x-transition-enter {
        transform: translateX(-15px);
    }
    .scroll-x-transition-leave-to {
        transform: translateX(15px);
    }
</style>