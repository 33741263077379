export default {
    props: {
        settings: {type: Object}
    },
    methods: {
        buildAssetPath(src) {
            // let prefix = 'http://showroom2go.ch';
            //let prefix = 'file:///C:/Showroom';

            let prefix = '';
            console.log('prefix');
            console.log(this.settings);
            if(this.settings.is_remote)
            {
                prefix = 'https://fire.showroom.astormuellergroup.com/resources/public/dtc/media/showroom_remote';
            }
            else if(this.settings.is_locale)
            {
                prefix = 'https://10.155.101.81';
            }
            else
            {
                prefix = 'https://fire.showroom.astormuellergroup.com/resources/public/dtc/media/showroom';
            }

            if(!src) return '';

            return `${prefix}${src}`;
        },
    }
}