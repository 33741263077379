<template>
    <div class="topics--mood-board">
        <div class="grid" id="grid" v-bind:style="getGridStyle">
            <div class="grid--item" v-for="(item,x) in items" :style="{'grid-area': 'i-' + (x + 1)}" :class="['i-' + (x + 1)]" :key="item.id">
                <bg-image-loader :src="buildAssetPath(getThumbName(item.image))" background-size="contain"></bg-image-loader>
            </div>
        </div>
    </div>
</template>

<script>
import AssetPathBuilder from "@/assets/js/mixins/AssetPathBuilder";
import ImageLoader from "@/components/ImageLoader";
import { gridLayoutsMixin} from "@/mixins/gridLayoutsMixin";

export default {
    name: 'TopicsMood',
    components: {
        ImageLoader,
    },
    mixins: [gridLayoutsMixin, AssetPathBuilder],
    props: { content:{type: Object}, state: {type: Object}, settings: {type: Object} },
    methods: {
        getThumbName(name) {
            if(this.items.length >  20) {
                return name.replace("/shoes/","/shoes/thumbs/")
            } else {
                return name;
            }
        }
    },
    mounted(){
    }
}
</script>

<style lang="scss" scoped>
    .transition--container {
        position: relative;
        width: 100%;
        height: 100%;
    }
    .grid {
        display: grid;
        width: 100vw;
        height: 100vh;
        grid-gap: 0.5em;
        padding: 1em;
        grid-template-rows: auto;
        grid-template-columns: auto;
    }
    .grid--item {
        background-color: rgb(245, 245, 245);
        position: relative;
        .bg--image {
            background-repeat: no-repeat;
            background-position: center;
        }
    }
</style>