module.exports = {
    brightness: (hex) => {
        // strip the leading # if it's there
        hex = hex.replace(/^\s*#|\s*$/g, '');

        // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
        if(hex.length === 3){
            hex = hex.replace(/(.)/g, '$1$1');
        }

        let r = parseInt(hex.substr(0, 2), 16),
            g = parseInt(hex.substr(2, 2), 16),
            b = parseInt(hex.substr(4, 2), 16);

        return (r + b + g) / (3*255);
    }
};